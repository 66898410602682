import { Project } from "@/types/entities/Project";
import { Button } from "@/components/ui/button";
import { SeevClient } from "@/lib/SeevClient";
import { useState } from "react";
import { X, File } from "lucide-react";
import { Outline, OutlineSection, Placeholder } from "@/types/entities/Outline";
import { FormattedMessage } from "react-intl";
import { FileUploadZone } from "@/components/UploadZone/UploadZone";

interface PlaceholderCardProps {
  project: Project;
  setProject: (project: Project) => void;
}

interface UploadState {
  [placeholderId: string]: {
    uploading: boolean;
    documentId?: string;
    documentName?: string;
  };
}

function PlaceholderCard({ project, setProject }: PlaceholderCardProps) {
  const [uploadState, setUploadState] = useState<UploadState>({});

  const handleFileUpload = async (file: File[], placeholderId: string) => {
    if (!file.length) return;

    setUploadState((prev) => ({
      ...prev,
      [placeholderId]: {
        uploading: true,
      },
    }));

    try {
      const response = await SeevClient.file.uploadNewFile(
        file[0],
        true,
        "PROPOSAL_DOCUMENT_PLACEHOLDER",
      );

      // Update the outline structure
      const newOutline: Outline = JSON.parse(JSON.stringify(project.outline));
      newOutline.sections = newOutline.sections.map((section) => ({
        ...section,
        directContents: section.directContents.map((content) =>
          content.id === placeholderId && content.type === "placeholder"
            ? {
                ...content,
                documentId: response.id,
                documentName: response.name,
              }
            : content,
        ),
      }));

      // Update the project
      await SeevClient.project.updateProject(project.id, {
        outline: newOutline,
      });

      setProject({
        ...project,
        outline: newOutline,
      });

      // Update upload state
      setUploadState((prev) => ({
        ...prev,
        [placeholderId]: {
          uploading: false,
          documentId: response.id,
          documentName: response.name,
        },
      }));
    } catch (error) {
      console.error("Upload failed:", error);
      setUploadState((prev) => {
        const newState = { ...prev };
        delete newState[placeholderId];
        return newState;
      });
    }
  };

  const removeDocument = async (placeholderId: string) => {
    try {
      const newOutline: Outline = JSON.parse(JSON.stringify(project.outline));
      newOutline.sections = newOutline.sections.map((section) => ({
        ...section,
        directContents: section.directContents.map((content) =>
          content.id === placeholderId && content.type === "placeholder"
            ? { ...content, documentId: null }
            : content,
        ),
      }));

      await SeevClient.project.updateProject(project.id, {
        outline: newOutline,
      });

      setProject({
        ...project,
        outline: newOutline,
      });

      setUploadState((prev) => {
        const newState = { ...prev };
        delete newState[placeholderId];
        return newState;
      });
    } catch (error) {
      console.error("Failed to remove document:", error);
    }
  };

  const getAllPlaceholders = (sections: OutlineSection[]) => {
    return sections.flatMap((section) =>
      section.directContents.filter(
        (content) => content.type === "placeholder",
      ),
    );
  };

  const getPlaceholderDescription = (placeholder: Placeholder) => {
    return placeholder.differentiation
      ? `${placeholder.name} - ${placeholder.differentiation}`
      : placeholder.name;
  };

  if (!project.outline) return null;
  return (
    <div className="flex flex-col p-4">
      <div className="font-bold mb-1 text-xl">
        <FormattedMessage id="project.outline.placeholders.title" />
      </div>
      <div className="h-0.5 w-full bg-border mb-6"></div>
      {getAllPlaceholders(project.outline.sections).map((placeholder) => (
        <div key={placeholder.id} className="mb-2">
          <div className="font-semibold mb-2">
            {getPlaceholderDescription(placeholder as Placeholder)}
          </div>
          <div className="pl-4">
            <FileUploadZone
              onUpload={(files) => handleFileUpload(files, placeholder.id)}
              description={getPlaceholderDescription(
                placeholder as Placeholder,
              )}
              options={{ multiple: false }}
            />

            {(placeholder.documentId ||
              uploadState[placeholder.id]?.documentId) && (
              <div className="mt-4">
                <FileItem
                  documentName={
                    uploadState[placeholder.id]?.documentName ||
                    placeholder.documentName ||
                    ""
                  }
                  onRemove={() => removeDocument(placeholder.id)}
                />
              </div>
            )}
          </div>
          <div className="h-px w-full my-4"></div>
        </div>
      ))}
    </div>
  );
}

function FileItem({
  documentName,
  onRemove,
}: {
  documentName: string;
  onRemove: () => void;
}) {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <File className="mr-2 h-4 w-4 text-muted-foreground" />
        <span className="text-sm text-muted-foreground">{documentName}</span>
      </div>
      <Button variant="ghost" size="icon" onClick={onRemove}>
        <X className="h-4 w-4 text-muted-foreground" />
      </Button>
    </div>
  );
}

export default PlaceholderCard;
