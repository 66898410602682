import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { OpportunitiesTable } from "./Components/table";
import { FormattedMessage } from "react-intl";
import { useCallback, useEffect, useState } from "react";
import { SeevClient } from "@/lib/SeevClient/SeevClient";
import {
  Opportunity,
  OpportunityDate,
  OpportunityDocumentStatus,
} from "@/types/entities/Opportunity";

function Opportunities() {
  const [opportunities, setOpportunities] = useState<Opportunity[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filteredOpportunities, setFilteredOpportunities] = useState<
    Opportunity[]
  >([]);
  const [currentStatus, setCurrentStatus] = useState<OpportunityDocumentStatus>(
    OpportunityDocumentStatus.INBOX,
  );
  const [currentDateFilter, setCurrentDateFilter] = useState<
    OpportunityDate | "TODAY"
  >("TODAY");

  const fetchOpportunitiesOnStatus = useCallback(
    async (status: OpportunityDocumentStatus) => {
      setIsLoading(true);
      try {
        const newOpportunities =
          await SeevClient.opportunities.getOpportunities(status);
        setOpportunities(newOpportunities);
        setFilteredOpportunities(
          filterOpportunities(newOpportunities, currentDateFilter),
        );
      } finally {
        setIsLoading(false);
      }
    },
    [currentDateFilter], // Ensure the current date filter is used
  );

  const filterOpportunities = (
    opportunities: Opportunity[],
    date: OpportunityDate | "TODAY",
  ) => {
    const today = new Date();
    return opportunities.filter((opportunity) => {
      const createdAt = new Date(opportunity.createdAt);
      switch (date) {
        case "TODAY":
          return createdAt.toDateString() === today.toDateString();
        case "ALL":
        default:
          return true;
      }
    });
  };

  const filterOpportunitiesOnDate = useCallback(
    (date: OpportunityDate | "TODAY") => {
      setCurrentDateFilter(date);
      setFilteredOpportunities(filterOpportunities(opportunities, date));
    },
    [opportunities],
  );

  useEffect(() => {
    fetchOpportunitiesOnStatus(currentStatus);
  }, [fetchOpportunitiesOnStatus, currentStatus]);

  return (
    <div className="flex w-full flex-col gap-4 p-6 pt-4">
      <Tabs defaultValue="ACTIVE">
        <div className="flex items-center justify-between space-y-2">
          <h2 className="text-3xl font-bold tracking-tight">
            <FormattedMessage id="opportunities.title" />
          </h2>
          <TabsList>
            <TabsTrigger
              value="ACTIVE"
              onClick={() => {
                setCurrentStatus(OpportunityDocumentStatus.INBOX);
              }}
            >
              <FormattedMessage id="opportunities.tabs.active" />
            </TabsTrigger>
            <TabsTrigger
              value="ARCHIVE"
              onClick={() => {
                setCurrentStatus(OpportunityDocumentStatus.ARCHIVED);
              }}
            >
              <FormattedMessage id="opportunities.tabs.archive" />
            </TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="ACTIVE">
          <OpportunitiesTable
            opportunities={filteredOpportunities}
            isLoading={isLoading}
            filterOnDate={filterOpportunitiesOnDate}
            currentStatus={OpportunityDocumentStatus.INBOX}
            fetchOpportunitiesOnStatus={fetchOpportunitiesOnStatus}
          />
        </TabsContent>
        <TabsContent value="ARCHIVE">
          <OpportunitiesTable
            opportunities={filteredOpportunities}
            isLoading={isLoading}
            filterOnDate={filterOpportunitiesOnDate}
            currentStatus={OpportunityDocumentStatus.ARCHIVED}
            fetchOpportunitiesOnStatus={fetchOpportunitiesOnStatus}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default Opportunities;
