import { Project } from "@/types/entities/Project";
import { FormattedMessage } from "react-intl";
import { Button } from "@/components/ui/button";
import { SeevClient } from "@/lib/SeevClient";
import { useState } from "react";
import { X, File } from "lucide-react";
import { RequiredCompanyDataDocument } from "@/types/entities/Project";
import { FileUploadZone } from "@/components/UploadZone/UploadZone";

interface RequiredCompanyDataCardProps {
  project: Project;
  setProject: (project: Project) => void;
}

interface UploadState {
  [key: string]: {
    documents: RequiredCompanyDataDocument[];
    uploading: boolean;
  };
}

function RequiredCompanyDataCard({
  project,
  setProject,
}: RequiredCompanyDataCardProps) {
  const [uploadState, setUploadState] = useState<UploadState>(() => {
    const initialState: UploadState = {};
    project.requiredCompanyData?.forEach((data) => {
      initialState[data.id] = {
        documents: data.documents || [],
        uploading: false,
      };
    });
    return initialState;
  });

  const handleFileUpload = async (files: File[], dataId: string) => {
    setUploadState((prev) => ({
      ...prev,
      [dataId]: {
        ...prev[dataId],
        uploading: true,
      },
    }));

    try {
      const uploadPromises = files.map(async (file) => {
        const response = await SeevClient.file.uploadNewFile(
          file,
          true,
          "PROPOSAL_DOCUMENT_CONTEXT",
        );
        return {
          id: response?.id || "",
          name: response?.name || "",
        };
      });

      const documents = await Promise.all(uploadPromises);
      const newDocuments = [
        ...(uploadState[dataId]?.documents || []),
        ...documents,
      ];

      const newRequiredCompanyData = project.requiredCompanyData?.map((data) =>
        data.id === dataId ? { ...data, documents: newDocuments } : data,
      );

      await SeevClient.project.updateProject(project.id, {
        requiredCompanyData: newRequiredCompanyData,
      });

      setProject({
        ...project,
        requiredCompanyData: newRequiredCompanyData,
      });

      setUploadState((prev) => ({
        ...prev,
        [dataId]: {
          documents: newDocuments,
          uploading: false,
        },
      }));
    } catch (error) {
      console.error("Upload failed:", error);
      setUploadState((prev) => ({
        ...prev,
        [dataId]: {
          ...prev[dataId],
          uploading: false,
        },
      }));
    }
  };

  const removeDocument = async (dataId: string, documentId: string) => {
    try {
      const newDocuments =
        uploadState[dataId]?.documents?.filter(
          (doc) => doc.id !== documentId,
        ) || [];

      const newRequiredCompanyData = project.requiredCompanyData?.map((data) =>
        data.id === dataId ? { ...data, documents: newDocuments } : data,
      );

      await SeevClient.project.updateProject(project.id, {
        requiredCompanyData: newRequiredCompanyData,
      });

      setProject({
        ...project,
        requiredCompanyData: newRequiredCompanyData,
      });

      setUploadState((prev) => ({
        ...prev,
        [dataId]: {
          ...prev[dataId],
          documents: newDocuments,
        },
      }));
    } catch (error) {
      console.error("Failed to remove document:", error);
    }
  };

  return (
    <div className="flex flex-col p-4">
      <div className="font-bold mb-1 text-xl">
        <FormattedMessage id="project.outline.requiredCompanyData.title" />
      </div>
      <div className="h-0.5 w-full bg-border mb-6"></div>
      {project.requiredCompanyData?.map((data) => (
        <div key={data.id} className="mb-2">
          <div className="font-semibold mb-2">{data.name}</div>
          <div className="pl-4">
            <FileUploadZone
              onUpload={(files) => handleFileUpload(files, data.id)}
              description={data.description}
            />

            <div className="mt-4">
              {/* Show all documents */}
              {uploadState[data.id]?.documents?.map((doc) => (
                <FileItem
                  key={doc.id}
                  document={doc}
                  onRemove={() => removeDocument(data.id, doc.id)}
                />
              ))}
            </div>
          </div>
          <div className="h-px w-full bg-border my-4"></div>
        </div>
      ))}
    </div>
  );
}

function FileItem({
  document,
  onRemove,
}: {
  document: RequiredCompanyDataDocument;
  onRemove: () => void;
}) {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <File className="mr-2 h-4 w-4 text-muted-foreground" />
        <span className="text-sm text-muted-foreground">{document.name}</span>
      </div>
      <Button variant="ghost" size="icon" onClick={onRemove}>
        <X className="h-4 w-4 text-muted-foreground" />
      </Button>
    </div>
  );
}

export default RequiredCompanyDataCard;
