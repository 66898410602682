import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { DialogDescription } from "@radix-ui/react-dialog";
import { FormattedMessage } from "react-intl";
import { ProjectCreate } from "@/types/entities/Project";
import { SeevClient } from "@/lib/SeevClient";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useState, useRef, useEffect } from "react";

interface AddProjectDialogProps {
  onCreate: (success: boolean) => void;
  initialValues?: {
    title?: string;
    agency?: string;
  };
  defaultOpen?: boolean;
}

const formSchema = z.object({
  title: z.string().min(3).max(255).optional(),
  agency: z.string().min(3).max(255).optional(),
  file: z.string(),
});

function AddProjectDialog({
  onCreate,
  initialValues,
  defaultOpen,
}: AddProjectDialogProps) {
  const fileInput = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(defaultOpen || false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: initialValues?.title || undefined,
      agency: initialValues?.agency || undefined,
      file: "",
    },
  });

  useEffect(() => {
    setOpen(defaultOpen || false);
  }, [defaultOpen]);

  const handleClose = () => {
    setOpen(false);
    onCreate(false);
    form.reset();
    if (fileInput.current) {
      fileInput.current.value = "";
    }
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setLoading(true);
    const files = fileInput.current?.files;

    try {
      if (!files || files.length === 0) {
        throw new Error("File is required");
      }

      const uploadPromises = Array.from(files).map((file) =>
        SeevClient.file.uploadNewFile(
          file,
          true,
          "RFP",
          "CREATE_PROJECT",
          null,
          true,
        ),
      );

      const documents = await Promise.all(uploadPromises);
      const documentIds = documents.map((doc) => doc.id);

      if (documents.some((doc) => !doc.id) || documentIds.length === 0) {
        throw new Error("Unable to upload one or more files");
      }

      const project: ProjectCreate = {
        title: values.title || "",
        agency: values.agency || "",
        documents: documentIds,
      };

      const responseProject = await SeevClient.project.createProject(project);

      if (!responseProject) {
        throw new Error("Unable to create project");
      }

      onCreate(true);
      form.reset();
      if (fileInput.current) {
        fileInput.current.value = "";
      }
      setOpen(false);
    } catch (error) {
      form.setError("root", {
        type: "manual",
        message: "",
      });
      onCreate(false);
    }

    setLoading(false);
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-6"
          >
            <DialogHeader>
              <DialogTitle>
                <FormattedMessage id="portfolio.new" />
              </DialogTitle>
            </DialogHeader>
            <DialogDescription className="flex mt-4 mb-4 gap-6 flex-col">
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <FormattedMessage id="portfolio.fields.title" />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="agency"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <FormattedMessage id="portfolio.fields.agency" />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="file"
                render={() => (
                  <FormItem>
                    <FormLabel required>
                      <FormattedMessage id="portfolio.fields.file" />
                    </FormLabel>
                    <FormControl>
                      <Input
                        type="file"
                        ref={fileInput}
                        accept=".pdf,.docx,.xlsx"
                        multiple
                        onChange={(e) => {
                          form.setValue("file", e.target.value);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </DialogDescription>
            <DialogFooter>
              <Button type="submit" disabled={loading} loading={loading}>
                <FormattedMessage
                  id={loading ? "global.creatingProject" : "global.create"}
                />
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default AddProjectDialog;
