import { Button } from "@/components/ui/button";
import { FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";
import { SearchInput } from "@/components/SearchInput/SearchInput";
import { OpportunityDate } from "@/types/entities/Opportunity";

interface ToolBarProps {
  setGlobalFilter: (value: string) => void;
  filterOnDate: (date: OpportunityDate | "TODAY") => void;
}

function ToolBar({ setGlobalFilter, filterOnDate }: ToolBarProps) {
  const intl = useIntl();
  const searchPlaceholder = intl.formatMessage({
    id: "opportunities.searchPlaceholder",
  });

  const [dateFilter, setDateFilter] = useState<OpportunityDate | "TODAY" | "">(
    "TODAY",
  );

  return (
    <div className="flex flex-row items-center justify-between w-full gap-2">
      <div className="flex items-center gap-2">
        {[
          {
            title: "opportunities.today",
            value: "TODAY",
          },
          {
            title: "opportunities.all",
            value: "ALL",
          },
        ].map((filter) => (
          <Button
            key={filter.value}
            className={
              dateFilter !== filter.value ? "opacity-50" : "opacity-100"
            }
            onClick={() => {
              setDateFilter(filter.value as OpportunityDate);
              filterOnDate(filter.value as OpportunityDate);
            }}
          >
            <FormattedMessage id={filter.title} />
          </Button>
        ))}
      </div>
      <div className="flex w-full max-w-[300px]">
        <SearchInput
          placeholder={searchPlaceholder}
          onChange={(value) => setGlobalFilter(value)}
        />
      </div>
    </div>
  );
}

export default ToolBar;
