import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { DialogDescription } from "@radix-ui/react-dialog";
import { FormattedMessage, useIntl } from "react-intl";
import { getRegionName, Opportunity } from "@/types/entities/Opportunity";
import { SeevClient } from "@/lib/SeevClient";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useState } from "react";
import { Textarea } from "@/components/ui/textarea";
import { useLanguage } from "@/providers/LanguageProvider";
import { useUser } from "@/hooks/UserHook/UserHook";

interface ShareOpportunityDialogProps {
  opportunity: Opportunity;
  open: boolean;
  onClose: () => void;
}

function ShareOpportunityDialog({
  opportunity,
  open,
  onClose,
}: ShareOpportunityDialogProps) {
  const intl = useIntl();
  const { language } = useLanguage();
  const user = useUser();

  const formSchema = z.object({
    emails: z.string().min(1, {
      message: intl.formatMessage({ id: "opportunities.share.emails.error" }),
    }),
    note: z.string().min(3).max(3000).optional(),
    subject: z.string().min(3).max(3000).optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      emails: "",
      note: intl.formatMessage({ id: "opportunities.share.note.intro" }),
      subject: intl.formatMessage({
        id: "opportunities.share.subject.default",
      }),
    },
  });

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setLoading(true);
    const emails = values.emails.split(",").map((email) => email.trim());

    try {
      await SeevClient.opportunities.shareOpportunity(
        opportunity.id,
        emails,
        values.note ?? "",
        opportunity.title,
        opportunity.entity,
        getRegionName(opportunity.areaId),
        opportunity.url,
        values.subject ?? "",
        language,
        user?.email ?? "",
      );
      onClose();
    } catch (error) {
      form.setError("root", {
        type: "manual",
        message: "",
      });
    }

    setLoading(false);
  };

  return (
    <Dialog open={open} onOpenChange={(newOpen) => !newOpen && onClose()}>
      <DialogContent className="overflow-auto">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-6"
          >
            <DialogHeader>
              <DialogTitle>
                <FormattedMessage id="global.share" />
              </DialogTitle>
            </DialogHeader>
            <DialogDescription className="flex mt-4 mb-4 gap-6 flex-col">
              <FormField
                control={form.control}
                name="emails"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <FormattedMessage id="opportunities.share.emails.title" />
                    </FormLabel>
                    <FormControl>
                      <ReactMultiEmail
                        {...field}
                        emails={field.value.split(",")}
                        onChange={(emails) =>
                          form.setValue("emails", emails.join(","))
                        }
                        getLabel={(email, index, removeEmail) => (
                          <div data-tag key={index}>
                            <div data-tag-item>{email}</div>
                            <span
                              data-tag-handle
                              onClick={() => removeEmail(index)}
                            >
                              ×
                            </span>
                          </div>
                        )}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="subject"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <FormattedMessage id="opportunities.share.subject.title" />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="note"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <FormattedMessage id="opportunities.share.note.title" />
                    </FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </DialogDescription>
            <DialogFooter>
              <Button type="submit" disabled={loading}>
                <FormattedMessage id="global.share" />
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default ShareOpportunityDialog;
