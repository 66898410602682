import { ColumnDef } from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { FormattedMessage } from "react-intl";
import FileEntity, { Status } from "@/types/entities/File";
import {
  DownloadIcon,
  Pencil1Icon,
  TrashIcon,
  FileIcon,
} from "@radix-ui/react-icons";
import { Loader2, X, ArrowUpDown } from "lucide-react";
import { DateTime } from "luxon";
import { getExtImage } from "@/utils/progress-color";

function createColumns(
  onDownload: (file: FileEntity) => void,
  onDelete: (file: FileEntity) => void,
  onPreview: (file: FileEntity) => void,
): ColumnDef<FileEntity>[] {
  return [
    {
      accessorKey: "typeName",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            <FormattedMessage id="fileManager.fields.type" />
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => (
        <div>
          {(row.getValue("status") as Status) === "PARSE_FAILED" ? (
            <X className="ml-1 h-5 w-5 text-red-500" />
          ) : (row.getValue("status") as Status) === "PARSED" ? (
            <FormattedMessage
              id={`fileManager.type.${row.getValue("typeName")}`}
            />
          ) : (
            <Loader2 className="h-4 w-4 animate-spin" />
          )}
        </div>
      ),
    },
    {
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            <FormattedMessage id="fileManager.fields.name" />
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => (
        <div className="flex items-center gap-1.5">
          <img
            src={getExtImage(row?.original?.extension)}
            className="h-4"
            alt="ext"
          />
          <div>{row.getValue("name")}</div>
        </div>
      ),
    },
    {
      accessorKey: "updatedAt",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            <FormattedMessage id="fileManager.fields.updatedAt" />
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => (
        <div>
          {DateTime.fromISO(row.getValue("updatedAt")).toFormat("MMMM d, yyyy")}
        </div>
      ),
    },
    {
      accessorKey: "actions",
      header: () => {
        return <></>;
      },
      cell: ({ row }) => (
        <div className="gap-2 flex flex-row">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onPreview(row.original)}
          >
            <FileIcon />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onDownload(row.original)}
          >
            <DownloadIcon />
          </Button>
          {false && (
            <Button
              variant="ghost"
              size="icon"
              onClick={() => console.log("Edit", row.original.id)}
            >
              <Pencil1Icon />
            </Button>
          )}
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onDelete(row.original)}
          >
            <TrashIcon />
          </Button>
        </div>
      ),
    },
    {
      accessorKey: "createdAt",
    },
    {
      accessorKey: "status",
    },
  ];
}

export default createColumns;
