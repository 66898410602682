// type OpportunityType =
//   | "Demande de proposition"
//   | "RFQ"
//   | "Demande de renseignements"
//   | "Contrat de gré à gré"
//   | "Avis d'appel d'offres"
//   | "RFI"
//   | "Avis d'intention"
//   | "Demande de proposition découlant d'un arrangement en matière d'approvisionnement"
//   | null;

type OpportunityDate = "ALL" | "TODAY";
type OpportunityStatus = "ACTIVE" | "ARCHIVE";

enum OpportunityDocumentStatus {
  INBOX = "0",
  PORTFOLIO = "1",
  ARCHIVED = "2",
  TRASH = "3",
}

interface Opportunity {
  id: string;
  ref: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string | null;
  entity: string;
  countryId: string;
  areaId: number;
  languageId: string;
  startDate: string;
  endDate?: string;
  url: string;
  status: OpportunityDocumentStatus;
  recommendation?: string;
  clientCategory?: string;
}

export const CanadianRegionsMap = {
  49: "ON",
  50: "QC",
  51: "NS",
  52: "NB",
  53: "MB",
  54: "BC",
  55: "PE",
  56: "SK",
  57: "AB",
  58: "NL",
  59: "NT",
  60: "YT",
  61: "NU",
  67: "Unknown",
} as const;

export const CLIENT_CATEGORIES = [
  "CANADA_GOVERNMENT",
  "QUEBEC_GOVERNMENT",
  "QUEBEC_NON_GOVERNMENT",
  "OTHER",
] as const;

export type CanadianRegionId = keyof typeof CanadianRegionsMap;

export function getRegionName(id: number): string {
  return CanadianRegionsMap[id as CanadianRegionId];
}

export type { Opportunity, OpportunityDate, OpportunityStatus };

export { OpportunityDocumentStatus };
