import {
  Project,
  ProjectCreate,
  ProjectUpdate,
  PortfolioProject,
} from "@/types/entities/Project";
import { AuthManager } from "../AuthManager";

class ProjectController {
  private authManager: AuthManager;

  constructor(AuthManager: AuthManager) {
    this.authManager = AuthManager;
  }

  public async getProjects() {
    try {
      return this.authManager.request<PortfolioProject[]>(
        "GET",
        `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/`,
      );
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public async getProject(projectId: string) {
    try {
      return this.authManager.request<Project>(
        "GET",
        `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/${projectId}/`,
      );
    } catch (error) {
      console.error(error);
      return {} as Project;
    }
  }

  public async deleteProject(projectId: string) {
    try {
      return this.authManager.request(
        "DELETE",
        `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/${projectId}/`,
      );
    } catch (error) {
      console.error(error);
    }
  }

  public async updateProject(projectId: string, project: ProjectUpdate) {
    try {
      return this.authManager.request<Project>(
        "PATCH",
        `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/${projectId}/`,
        project,
      );
    } catch (error) {
      console.error(error);
      return {} as Project;
    }
  }

  public async createProject(project: ProjectCreate) {
    try {
      const projectResponse = await this.authManager.request<Project>(
        "POST",
        `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/`,
        project,
      );

      return projectResponse as Project;
    } catch (error) {
      console.error(error);
      return {} as Project;
    }
  }

  public async shareProject(
    projectId: string,
    emails: string[],
    note: string,
    subject: string,
    language: string,
    sendersEmail: string,
  ) {
    return this.authManager.request(
      "POST",
      `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/${projectId}/share/`,
      {
        emails,
        note,
        subject,
        language,
        cc: [sendersEmail],
      },
    );
  }

  public async generateProposal(projectId: string) {
    return this.authManager.request<ArrayBuffer>(
      "POST",
      `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/${projectId}/generate-proposal/`,
      undefined,
      "arraybuffer",
    );
  }

  public async getProposal(projectId: string) {
    return this.authManager.request<ArrayBuffer>(
      "GET",
      `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/${projectId}/generate-proposal/`,
      undefined,
      "arraybuffer",
    );
  }

  public async generateRequiredCompanyData(projectId: string) {
    return this.authManager.request<JSON>(
      "POST",
      `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/${projectId}/required-company-data/extract/`,
    );
  }

  public async extractSummary(projectId: string) {
    return this.authManager.request<JSON>(
      "POST",
      `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/${projectId}/summary/extract/`,
    );
  }

  public async extractRequirements(projectId: string) {
    return this.authManager.request<JSON>(
      "POST",
      `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/${projectId}/requirements/extract/`,
    );
  }

  public async writeOutline(projectId: string) {
    return this.authManager.request<JSON>(
      "POST",
      `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/${projectId}/outline/write/`,
    );
  }

  public async extractTablesForms(projectId: string) {
    return this.authManager.request<JSON>(
      "POST",
      `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/${projectId}/tables-forms/extract/`,
    );
  }

  public async fillTableForm(projectId: string, tableFormId: string) {
    return this.authManager.request<JSON>(
      "POST",
      `/organizations/${this.authManager.organizationId}/portfolios/${this.authManager.portfolioId}/projects/${projectId}/tables-forms/${tableFormId}/fill/`,
    );
  }
}
export { ProjectController };
