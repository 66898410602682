import { PortfolioProject, ProjectProgress } from "@/types/entities/Project";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { FormattedMessage } from "react-intl";
import { ArrowUpDown } from "lucide-react";
import { calculateProgressColor } from "@/utils/progress-color";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  calculateColorDays,
  calculateDaysBetween,
} from "@/utils/progress-color";
import { FilterDropdownBtn } from "@/components/ui/filter-dropdown-btn";

const PROGRESS_VALUES = [
  "GO_NO_GO",
  "PROPOSAL",
  "SUBMITTED",
  "WON",
  "COMPLETED",
  "NO_GO",
  "CANCELED",
  "LOST",
];

function createColumns(
  onChangeProgress: (project_id: string, progress: ProjectProgress) => void,
): ColumnDef<PortfolioProject>[] {
  return [
    {
      accessorKey: "title",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            <FormattedMessage id="portfolio.fields.title" />
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => <div>{row.getValue("title")}</div>,
    },
    {
      accessorKey: "agency",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            <FormattedMessage id="portfolio.fields.agency" />
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => <div>{row.getValue("agency")}</div>,
    },
    {
      accessorKey: "progress",
      filterFn: "arrIncludesSome",
      header: ({ column }) => {
        return (
          <FilterDropdownBtn
            column={column}
            values={PROGRESS_VALUES}
            labelId="project.fields.projectProgress"
            translationPrefix="project.projectProgress"
          />
        );
      },
      cell: ({ row }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div
                className={`${calculateProgressColor(row.getValue("progress"))} w-max text-gray-700 cursor-pointer flex items-center gap-2 px-2.5 py-1 rounded-lg`}
              >
                <FormattedMessage
                  id={
                    row.getValue("progress")
                      ? `project.projectProgress.${row.getValue("progress")}`
                      : "project.summaryFields.noSpecification"
                  }
                />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="center">
              {PROGRESS_VALUES.map((item) => (
                <DropdownMenuItem
                  className={`m-0.5 cursor-pointer`}
                  key={item}
                  onClickCapture={() => {
                    onChangeProgress(
                      row?.original?.id,
                      item as ProjectProgress,
                    );
                  }}
                >
                  <div
                    className={`${calculateProgressColor(item as ProjectProgress)} h-4 w-4 rounded mr-1.5`}
                  />
                  <FormattedMessage id={`project.projectProgress.${item}`} />
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
    {
      accessorKey: "bidLead",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            <FormattedMessage id="portfolio.fields.bidLead" />
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => <div>{row.getValue("bidLead")}</div>,
    },
    {
      accessorKey: "accountManager",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            <FormattedMessage id="portfolio.fields.accountManager" />
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => <div>{row.getValue("accountManager")}</div>,
    },
    // {
    //   accessorKey: "qnaDeadline",
    //   header: () => {
    //     return (
    //       <Button variant="ghost">
    //         <FormattedMessage id="portfolio.fields.qnaDeadline" />
    //       </Button>
    //     );
    //   },
    // },
    {
      accessorKey: "summary.rfpApplicationDeadline",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            <FormattedMessage id="portfolio.fields.rfpApplicationDeadline" />
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) =>
        row.original.summary?.rfpApplicationDeadline ? (
          <div className="flex justify-center">
            <div
              className={`${calculateColorDays(
                calculateDaysBetween(
                  row?.original?.summary?.rfpApplicationDeadline,
                ) ?? 0,
              )}
              w-max text-gray-700 px-2.5 py-1 rounded-lg text-center`}
            >
              {row.original.summary?.rfpApplicationDeadline}
            </div>
          </div>
        ) : (
          <div className="text-center">-</div>
        ),
    },
    {
      accessorKey: "createdAt",
    },
    // {
    //   accessorKey: "region",
    //   header: ({ column }) => {
    //     return (
    //       <Button
    //         variant="ghost"
    //         onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
    //       >
    //         <FormattedMessage id="portfolio.fields.region" />
    //         <ArrowUpDown className="ml-2 h-4 w-4" />
    //       </Button>
    //     );
    //   },
    //   cell: ({ row }) => <div>{row.getValue("region")}</div>,
    // },
    // {
    //   accessorKey: "industry",
    //   header: ({ column }) => {
    //     return (
    //       <Button
    //         variant="ghost"
    //         onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
    //       >
    //         <FormattedMessage id="portfolio.fields.industry" />
    //         <ArrowUpDown className="ml-2 h-4 w-4" />
    //       </Button>
    //     );
    //   },
    //   cell: ({ row }) => <div>{row.getValue("industry")}</div>,
    // },
    // {
    //   accessorKey: "function",
    //   header: ({ column }) => {
    //     return (
    //       <Button
    //         variant="ghost"
    //         onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
    //       >
    //         <FormattedMessage id="portfolio.fields.function" />
    //         <ArrowUpDown className="ml-2 h-4 w-4" />
    //       </Button>
    //     );
    //   },
    //   cell: ({ row }) => <div>{row.getValue("function")}</div>,
    // },
  ];
}

export default createColumns;
