import AddProjectDialog from "@/modules/MainNav/components/AddProjectDialog";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ProjectProgress } from "@/types/entities/Project";
import { useState } from "react";
import { SearchInput } from "@/components/SearchInput/SearchInput";
import { ListFilter } from "lucide-react";
import { Button } from "@/components/ui/button";
import { PlusIcon } from "@radix-ui/react-icons";

interface ToolBarProps {
  setGlobalFilter: (value: string) => void;
  onCreateProject: () => void;
  filterOnProgress: (progress: ProjectProgress | "ALL") => void;
}

function ToolBar({
  setGlobalFilter,
  onCreateProject,
  filterOnProgress,
}: ToolBarProps) {
  const intl = useIntl();
  const searchPlaceholder = intl.formatMessage({
    id: "portfolio.searchPlaceholder",
  });
  const [progressFilter, setProgressFilter] = useState<
    ProjectProgress | "ALL" | ""
  >("");
  const [addProjectDialogOpen, setAddProjectDialogOpen] = useState(false);

  return (
    <div className="flex items-center justify-between">
      <Button
        variant="default"
        size="sm"
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation();
          setAddProjectDialogOpen(true);
        }}
      >
        <PlusIcon className="mr-2 h-4 w-4" />
        <FormattedMessage id="portfolio.new" />
      </Button>
      <AddProjectDialog
        onCreate={() => {
          setAddProjectDialogOpen(false);
          onCreateProject();
        }}
        defaultOpen={addProjectDialogOpen}
      />
      <div className="flex items-center space-x-4">
        <SearchInput
          placeholder={searchPlaceholder}
          onChange={(value) => setGlobalFilter(value)}
        />
        <Select
          defaultValue={progressFilter}
          value={progressFilter}
          onValueChange={(value) => {
            setProgressFilter(value as ProjectProgress);
            filterOnProgress(value as ProjectProgress);
          }}
        >
          <SelectTrigger className="max-w-sm" hideIcon>
            <ListFilter className="mr-2 h-4 w-4" />
            <SelectValue
              placeholder={intl.formatMessage({
                id: "portfolio.progressFilterPlaceholder",
              })}
            />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="ALL">
              <FormattedMessage id="global.noFilter" defaultMessage="ALL" />
            </SelectItem>
            <SelectItem value="GO_NO_GO">
              <FormattedMessage id="project.projectProgress.GO_NO_GO" />
            </SelectItem>
            <SelectItem value="NO_GO">
              <FormattedMessage id="project.projectProgress.NO_GO" />
            </SelectItem>
            <SelectItem value="PROPOSAL">
              <FormattedMessage id="project.projectProgress.PROPOSAL" />
            </SelectItem>
            <SelectItem value="CANCELED">
              <FormattedMessage id="project.projectProgress.CANCELED" />
            </SelectItem>
            <SelectItem value="SUBMITTED">
              <FormattedMessage id="project.projectProgress.SUBMITTED" />
            </SelectItem>
            <SelectItem value="WON">
              <FormattedMessage id="project.projectProgress.WON" />
            </SelectItem>
            <SelectItem value="COMPLETED">
              <FormattedMessage id="project.projectProgress.COMPLETED" />
            </SelectItem>
            <SelectItem value="LOST">
              <FormattedMessage id="project.projectProgress.LOST" />
            </SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}

export default ToolBar;
