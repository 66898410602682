import { Button } from "@/components/ui/button";
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { useRef, useState, useEffect, useCallback } from "react";

interface PageSelectorProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

function PageSelector(props: PageSelectorProps) {
  const { currentPage, totalPages, onPageChange } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [inputText, setInputText] = useState<string>(currentPage.toString());

  const handleInputChange = useCallback(() => {
    const pageNumber: number = parseInt(inputText) || 0;

    if (pageNumber >= 1 || pageNumber <= totalPages) {
      onPageChange(pageNumber);
    } else {
      setInputText(currentPage.toString());
    }
  }, [onPageChange, totalPages, inputText, currentPage]);

  useEffect(() => {
    if (inputRef.current && inputText) {
      const length = inputText.toString().length + 1;
      const width = length < 4 ? length * 8 + "px" : "32px";
      inputRef.current.style.width = width;
    }
  }, [inputText]);

  useEffect(() => {
    if (currentPage > 0 && currentPage <= totalPages) {
      setInputText(currentPage.toString());
    }
  }, [currentPage, totalPages]);

  return (
    <div className="flex mt-[450px] pr-4 w-full absolute justify-end z-[4000] pointer-events-none">
      <div className="flex pt-2 gap-2 flex-col justify-between items-center z-[4000] overscroll-auto bg-gray-500 bg-opacity-30 text-gray-600 rounded-md pointer-events-auto text-xs">
        <input
          ref={inputRef}
          className="w-3 text-center rounded-[3px]"
          value={inputText}
          id="txt"
          type="text"
          onChange={(e) => {
            // only allow empty string or numbers
            if (!e.target.value || /^[0-9]*$/.test(e.target.value)) {
              setInputText(e.target.value);
            }
          }}
          onBlur={() => {
            handleInputChange();
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleInputChange();
            }
          }}
        />
        <div>{totalPages}</div>
        <div className="flex flex-col">
          <Button
            size={"sm"}
            variant={"ghost"}
            onClick={() => {
              if (currentPage > 1) {
                onPageChange(currentPage - 1);
              }
            }}
            disabled={currentPage === 1}
          >
            <ChevronUpIcon />
          </Button>
          <Button
            size={"sm"}
            variant={"ghost"}
            onClick={() => {
              if (currentPage < totalPages) {
                onPageChange(currentPage + 1);
              }
            }}
            disabled={currentPage === totalPages}
          >
            <ChevronDownIcon />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PageSelector;
