import { useState, useRef, useEffect } from "react";
import { SearchX, Search, X } from "lucide-react";
// import { Bell, Settings, CircleHelp } from "lucide-react";
import { Input } from "@/components/ui/input";
import { featureFlags } from "@/config/featureFlags";
import { useIntl } from "react-intl";
import { SeevClient } from "@/lib/SeevClient";
import FileEntity, { SemanticSearchFileEntity } from "@/types/entities/File";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import PDFExplorer from "../../modules/FileRenderer/explorers/PDFExplorer/PDFExplorer";
import { FormattedMessage } from "react-intl";
import { getExtImage } from "@/utils/progress-color";
import useDebounce from "@/utils/debounce";

export function TopNav() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<SemanticSearchFileEntity[]>([]);
  const [showClear, setShowClear] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFilePreviewOpen, setIsFilePreviewOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState<FileEntity | null>(null);
  const [searchText, setSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const searchRef = useRef<HTMLDivElement>(null);
  const abortController = useRef<AbortController | null>(null);

  // Custom debounce hook
  const fetchResults = useDebounce(async (query: string) => {
    if (!query) return;
    setLoading(true);
    // Abort previous request if it exists
    if (abortController.current) {
      abortController.current.abort();
    }
    // Create new AbortController for this request
    abortController.current = new AbortController();
    try {
      const searchResponse = await SeevClient.file.semanticSearchFiles(
        query,
        abortController.current.signal,
      );
      setResults(
        searchResponse.sort((a, b) => b?.score - a?.score)?.slice(0, 5),
      );
      if (!abortController?.current?.signal?.aborted) {
        setIsOpen(true);
      }
    } finally {
      setLoading(false);
    }
  }, 500);

  // Handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);
    setShowClear(!!value);
    if (!value) {
      setIsOpen(false);
    } else {
      fetchResults(value);
    }
  };

  // Handle clear input
  const handleClear = () => {
    setQuery("");
    setResults([]);
    setShowClear(false);
    setIsOpen(false);
    // Abort ongoing request if it exists
    if (abortController.current) {
      abortController.current.abort();
    }
  };

  // Handle preview file
  const handleFilePreview = async (file: SemanticSearchFileEntity) => {
    const response = await SeevClient.file.downloadFile(file.documentId, true);
    if (!response) return;
    const fileURL = URL.createObjectURL(response);
    setPreviewFile({
      ...file,
      url: fileURL,
    });
    setIsFilePreviewOpen(true);
    if ("text" in file && "pageNumber" in file) {
      setSearchText(file.text);
      setPageNumber(file.pageNumber);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef]);

  return (
    <div className="w-full h-[4rem] bg-[#F3F4F6] dark:bg-[#121114] sticky top-0 z-10 rounded-br-3xl">
      {featureFlags().enableTopBar && (
        <div className="w-full flex justify-between gap-2 px-8 items-center">
          {/* Search Bar */}
          <div
            ref={searchRef}
            className="relative w-full max-w-xl"
            onClickCapture={() => {
              if (results?.length) {
                setIsOpen(true);
              }
            }}
          >
            <Search className="absolute left-4 top-1/2 h-5 w-5 -translate-y-1/2 text-muted-foreground" />
            <Input
              placeholder={intl.formatMessage({ id: "knowledge.search" })}
              className={`
                !outline-none focus-visible:!ring-0 focus-visible:!ring-offset-0 
                pl-12 !border-none !h-[40px] bg-background transition-colors duration-200
                ${isOpen ? "!rounded-t-[1.7rem]" : "!rounded-[1.7rem]"}
              `}
              value={query}
              onChange={handleInputChange}
            />

            {/* Loading Progress Line */}
            {loading && (
              <div className="bg-muted z-30 relative w-full max-w-lg -mt-0.5 h-[2px] rounded-full mx-4 overflow-hidden">
                <div className="absolute top-0 left-0 w-full h-full bg-primary animate-line-progress" />
              </div>
            )}

            {/* Clear Search */}
            {showClear && (
              <X
                className="absolute right-4 top-1/2 h-5 w-5 -translate-y-1/2 text-muted-foreground cursor-pointer"
                onClick={handleClear}
              />
            )}

            {/* Dropdown Menu */}
            {isOpen && (
              <div
                className={`
                absolute -mt-0.5 w-full !bg-background !border-t !border-t-muted 
              drop-shadow-md !rounded-b-[1.7rem] max-h-[800px] overflow-y-auto z-20 transition-colors duration-200
              `}
              >
                {results?.length ? (
                  <ul>
                    {results.map((result, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          handleFilePreview(result);
                        }}
                        className="flex justify-between gap-4 px-6 py-2 last:!pb-3 hover:bg-muted transition-all duration-200 cursor-pointer"
                      >
                        <span className="flex w-2/3 items-center gap-2">
                          <img
                            src={getExtImage(result.extension)}
                            className="h-4"
                            alt="ext"
                          />
                          <span className="flex w-full flex-col gap-0.5">
                            <span className="text-sm line-clamp-2">
                              {result.name}
                            </span>
                            <span className="!font-light text-xs text-muted-foreground">
                              {result.typeName}
                            </span>
                          </span>
                        </span>
                        <span className="text-muted-foreground text-xs w-max">
                          {DateTime.fromISO(result.updatedAt).toFormat(
                            "MMM d, yyyy",
                          )}
                        </span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="flex flex-col justify-center gap-1 !text-gray-300 dark:!text-gray-500 items-center h-full text-lg p-8">
                    <SearchX />
                    <FormattedMessage id="knowledge.notFound" />
                  </div>
                )}

                {/* See All */}
                {featureFlags().enableFileManager && (
                  <div
                    className="cursor-pointer text-muted-foreground hover:text-primary flex flex-col justify-start gap-1 !border-t !border-t-muted items-start px-6 text-sm py-2.5"
                    onClick={() => {
                      handleClear();
                      navigate(`/knowledge?query=${encodeURIComponent(query)}`);
                    }}
                  >
                    <FormattedMessage id="knowledge.seeAll" />
                  </div>
                )}
              </div>
            )}

            {/* Preview File */}
            <Dialog
              open={isFilePreviewOpen}
              onOpenChange={setIsFilePreviewOpen}
            >
              <DialogContent className="!min-w-[700px]">
                <PDFExplorer
                  fileUrl={previewFile?.url}
                  searchText={searchText}
                  pageNumber={pageNumber}
                  noBorder={true}
                />
              </DialogContent>
            </Dialog>
          </div>

          {/* Other Actions */}
          <div className="flex py-[1.4rem] items-center gap-3.5 mt-5">
            {/* <CircleHelp className="h-5 w-5 text-gray-400 hover:text-primary cursor-pointer" />
            <Settings className="h-5 w-5 text-gray-400 hover:text-primary cursor-pointer" />
            <Bell className="h-5 w-5 text-gray-400 hover:text-primary cursor-pointer" /> */}
          </div>
        </div>
      )}

      {/* Extra Decoration to handle rounded style*/}
      <div className="absolute top-[5.4rem] left-0 w-6 h-[1.75rem] bg-transparent">
        <div className="absolute bottom-0 left-0 w-[25px] h-[50px] bg-transparent rounded-tl-full shadow-[0_-1.5rem_0_0_#F3F4F6] dark:shadow-[0_-1.5rem_0_0_#121114]" />
      </div>
    </div>
  );
}
