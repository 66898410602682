import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { FormattedMessage } from "react-intl";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { PlusIcon, TrashIcon } from "@radix-ui/react-icons";
import { Textarea } from "@/components/ui/textarea";
import { Deliverable } from "@/types/entities/Project";

interface DeliverablesTableViewProps {
  edit: boolean;
  deliverables: Deliverable[];
  loading: boolean;
  onSubmit?: (newDeliverables: Deliverable[]) => void;
  onCancel?: () => void;
}

const deliverableSchema = z.object({
  title: z.string().min(1).max(255),
  description: z.string().min(1).max(1000),
  quantity: z.string(),
});

// Now add this object into an array
const deliverablesSchema = z.object({
  deliverables: z.array(deliverableSchema),
});

function DeliverablesTableView(props: DeliverablesTableViewProps) {
  const { edit, onCancel, onSubmit, deliverables, loading } = props;

  const submit = async (values: z.infer<typeof deliverablesSchema>) => {
    if (!onSubmit) return;
    onSubmit(values.deliverables);
  };

  const form = useForm<z.infer<typeof deliverablesSchema>>({
    resolver: zodResolver(deliverablesSchema),
    defaultValues: {
      deliverables: [
        ...deliverables.map((d) => ({ ...d, quantity: d.quantity ?? "" })),
      ],
    },
  });

  const array = useFieldArray({
    control: form.control,
    name: "deliverables",
  });

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(submit)}
        className="flex flex-col gap-6"
      >
        <Table>
          <TableBody>
            {array.fields.map((field, index) => (
              <TableRow key={field.id}>
                <TableCell>
                  <FormItem>
                    {edit ? (
                      <FormField
                        control={form.control}
                        name={`deliverables.${index}.title`}
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Textarea {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    ) : (
                      <b>{field.title}</b>
                    )}
                  </FormItem>
                </TableCell>
                <TableCell>
                  {edit ? (
                    <FormItem>
                      <FormField
                        control={form.control}
                        name={`deliverables.${index}.description`}
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Textarea {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </FormItem>
                  ) : (
                    <div>{field.description}</div>
                  )}
                </TableCell>
                <TableCell>
                  {edit ? (
                    <FormItem>
                      <FormField
                        control={form.control}
                        name={`deliverables.${index}.quantity`}
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Textarea {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </FormItem>
                  ) : (
                    <div>{field.quantity}</div>
                  )}
                </TableCell>
                {edit && (
                  <TableCell>
                    <Button
                      variant="ghost"
                      size="icon"
                      disabled={loading}
                      onClick={() => array.remove(index)}
                    >
                      <TrashIcon />
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {edit && (
          <div className="flex w-full justify-center gap-2">
            <Button
              type="button"
              variant={"outline"}
              disabled={loading}
              onClick={() =>
                array.append({
                  title: "",
                  description: "",
                  quantity: "",
                })
              }
            >
              <PlusIcon />
              <FormattedMessage id="global.add" />
            </Button>
          </div>
        )}
        {edit && (
          <div className="flex gap-2 mr-4 justify-end">
            <Button onClick={onCancel} variant="outline" disabled={loading}>
              <FormattedMessage id="global.cancel" />
            </Button>
            <Button type="submit" disabled={loading}>
              <FormattedMessage id="global.save" />
            </Button>
          </div>
        )}
      </form>
    </Form>
  );
}

export default DeliverablesTableView;
