import { Deliverable, Summary } from "@/types/entities/Project";
import { FormattedMessage } from "react-intl";
import DeliverablesListCard from "./DeliverablesListCard";

interface DeliverablesSectionProps {
  summary: Summary;
  loading: boolean;
  onSubmit: (summary: Summary) => Promise<void>;
}

function DeliverablesSection(props: DeliverablesSectionProps) {
  const { summary, onSubmit, loading } = props;

  const handleSubmit = async (deliverables: Deliverable[]) => {
    await onSubmit({
      ...summary,
      deliverables,
    });
  };

  return (
    <DeliverablesListCard
      deliverables={summary.deliverables}
      title={
        <span>
          <FormattedMessage id="project.summaryFields.deliverables.title" />
          {summary.deliverables.length === 0 && (
            <span className="text-muted-foreground text-sm ml-1">
              (<FormattedMessage id="global.empty" />)
            </span>
          )}
        </span>
      }
      onSubmit={handleSubmit}
      loading={loading}
    />
  );
}

export default DeliverablesSection;
