import { ReactNode, useState } from "react";
import EditableCard, { ViewMode } from "../../components/EditableCard";
import { Deliverable } from "@/types/entities/Project";
import DeliverablesTableView from "./DeliverablesTableView";

interface DeliverablesListProps {
  title: ReactNode;
  deliverables: Deliverable[];
  onSubmit: (newElements: Deliverable[]) => void;
  loading: boolean;
}

function DeliverablesListCard(props: DeliverablesListProps) {
  const { deliverables, onSubmit, loading, title } = props;
  const [viewMode, setViewMode] = useState<ViewMode>("view");

  const handleSubmit = async (newElements: Deliverable[]) => {
    await onSubmit(newElements);
    setViewMode("view");
  };

  return (
    <EditableCard
      title={title}
      loading={loading}
      viewMode={viewMode}
      onViewModeChange={(newViewMode) => setViewMode(newViewMode)}
      form={
        <DeliverablesTableView
          edit={true}
          loading={loading}
          onSubmit={handleSubmit}
          onCancel={() => setViewMode("view")}
          deliverables={deliverables}
        />
      }
      view={
        <DeliverablesTableView
          edit={false}
          deliverables={deliverables}
          loading={loading}
        />
      }
    />
  );
}

export default DeliverablesListCard;
