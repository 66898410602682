import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useUser } from "@/hooks/UserHook/UserHook";
import { useSidebar } from "@/providers";

export function UserNav() {
  // const navigate = useNavigate();
  const user = useUser();
  const { isCollapsed } = useSidebar();
  return (
    <div className="w-full py-4">
      <div className="flex items-center pl-5">
        <Avatar>
          <AvatarImage />
          <AvatarFallback>
            {user?.firstName?.length ? user.firstName.charAt(0) : ""}
            {user?.lastName?.length ? user.lastName.charAt(0) : ""}
          </AvatarFallback>
        </Avatar>
        {!isCollapsed && (
          <div className="ml-3 flex flex-col gap-1 font-light">
            <p className="text-sm !text-foreground w-[140px] truncate">
              {user?.firstName || ""} {user?.lastName || ""}
            </p>
            <p className="text-xs text-muted-foreground w-[140px] truncate">
              {" "}
              {user?.email || ""}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
