import { Column } from "@tanstack/react-table";
import { Button } from "./button";
import { FormattedMessage } from "react-intl";
import { ArrowUpDown } from "lucide-react";
import { MultiSelect } from "./multi-select-table-field";
import { useIntl } from "react-intl";

interface FilterDropdownBtnProps<TData> {
  column: Column<TData>;
  values: readonly string[];
  labelId: string;
  translationPrefix: string;
}

export function FilterDropdownBtn<TData>({
  column,
  values,
  labelId,
  translationPrefix,
}: FilterDropdownBtnProps<TData>) {
  const intl = useIntl();

  const optionsList = values.map((item) => ({
    label: intl.formatMessage({ id: `${translationPrefix}.${item}` }),
    value: item,
  }));

  return (
    <div className="flex flex-row">
      <Button
        variant="ghost"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        <FormattedMessage id={labelId} />
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </Button>
      <MultiSelect
        options={optionsList}
        onValueChange={(value) => column.setFilterValue(value)}
        defaultValue={[]}
      />
    </div>
  );
}
