import { useDropzone, DropzoneOptions } from "react-dropzone";
import { Upload } from "lucide-react";

function FileUploadZone({
  onUpload,
  description,
  className,
  options,
}: {
  onUpload: (files: File[]) => void;
  description: string;
  className?: string;
  options?: Omit<DropzoneOptions, "onDrop">;
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onUpload,
    ...options,
  });

  return (
    <div
      {...getRootProps()}
      className={`
        ${className}
        border-2 border-dashed rounded-2xl p-4 text-center cursor-pointer
        transition-colors duration-200
        ${isDragActive ? "border-primary bg-primary/10" : "border-border bg-muted/30"}
      `}
    >
      <input {...getInputProps()} />
      <Upload className="mx-auto h-6 w-6 text-muted-foreground mb-2" />
      <p className="text-sm text-muted-foreground">{description}</p>
    </div>
  );
}

export { FileUploadZone };
