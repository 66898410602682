import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useState, useRef } from "react";
import { SeevClient } from "@/lib/SeevClient";
import { Plus } from "lucide-react";

interface AddFileToProjectDialogProps {
  projectId: string;
  onAddFile: () => void;
}

const formSchema = z.object({
  file: z
    .any()
    .refine((files) => files && files.length > 0, "File is required"),
});

function AddFileToProjectDialog({
  projectId,
  onAddFile,
}: AddFileToProjectDialogProps) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });
  const fileInput = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const onSubmit = async () => {
    setLoading(true);

    const files = fileInput.current?.files ? fileInput.current.files : null;

    try {
      if (!files) {
        throw new Error("File is required");
      }

      const uploadPromises = Array.from(files).map((file) =>
        SeevClient.file.uploadNewFile(
          file,
          true,
          "RFP",
          "UPDATE_PROJECT",
          projectId,
        ),
      );

      const documents = await Promise.all(uploadPromises);

      if (documents.some((doc) => !doc)) {
        throw new Error("Unable to upload one or more files");
      }

      onAddFile();

      form.reset();
      setOpen(false);
    } catch (error) {
      form.setError("root", {
        type: "manual",
        message: (error as Error).message || "Failed to upload file",
      });
    }

    setLoading(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="secondary" size="sm">
          <Plus className="mr-2 h-4 w-4" />
          <FormattedMessage id="project.addFile.title" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-6"
          >
            <DialogHeader>
              <DialogTitle>
                <FormattedMessage id="project.addFile.title" />
              </DialogTitle>
            </DialogHeader>
            <FormField
              control={form.control}
              name="file"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>
                    <FormattedMessage id="project.addFile.file" />
                  </FormLabel>
                  <FormControl>
                    {/* For now the the server only supports one file upload at a time */}
                    <Input
                      type="file"
                      {...field}
                      ref={fileInput}
                      accept=".pdf,.docx,.xlsx"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button type="submit" disabled={loading} loading={loading}>
                <FormattedMessage id="project.addFile.submit" />
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default AddFileToProjectDialog;
