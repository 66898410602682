import {
  EllipsisVertical,
  Building2,
  CalendarClock,
  Flag,
  Loader,
  WandSparkles,
  Settings,
  Trash2,
  CloudDownload,
  Ellipsis,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Project, ProjectProgress } from "@/types/entities/Project";
// import { Badge } from "@/components/ui/badge";
import RemoveProjectDialog from "./components/RemoveProjectDialog";
import { FormattedMessage, useIntl } from "react-intl";
import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { TabsList, TabsTrigger } from "@/components/ui/tabs";
import { SeevClient } from "@/lib/SeevClient";
import { useNavigate } from "react-router-dom";
import ProjectSettingsDialog from "./components/ProjectSettingDialog";
import { featureFlags } from "@/config/featureFlags";
import {
  calculateColorDays,
  calculateDaysBetween,
  calculateProgressColor,
} from "@/utils/progress-color";
import { useLanguage } from "@/providers/LanguageProvider";
import { toast } from "@/hooks/ToastHook/use-toast";
import { Toaster } from "@/components/Toaster/Toaster";

interface ToolBarProps {
  project: Project;
  onSave: () => void;
  setProject: (project: Project) => void;
}

function ToolBar(props: ToolBarProps) {
  const { project, onSave, setProject } = props;

  const navigate = useNavigate();

  const intl = useIntl();

  const [removeProjectDialogOpen, setRemoveProjectDialogOpen] = useState(false);
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [projectProgress, setProjectProgress] = useState(project.progress);
  const [activeTab, setActiveTab] = useState<string>("dashboard");
  const [isProposalBeingGenerated, setIsProposalBeingGenerated] =
    useState<boolean>(project.proposalStatus === "IN_PROGRESS");
  const [
    isRequiredCompanyDataBeingGenerated,
    setIsRequiredCompanyDataBeingGenerated,
  ] = useState<boolean>(project.requiredCompanyDataStatus === "PARSING");
  const [isRequiredCompanyDataReady, setIsRequiredCompanyDataReady] =
    useState<boolean>(project.requiredCompanyDataStatus === "PARSED");
  const [removingProject, setRemovingProject] = useState(false);
  const [isProposalReady, setIsProposalReady] = useState(
    project.proposalStatus === "COMPLETED",
  );
  const [isExpanded, setIsExpanded] = useState(true);
  const [isTruncated, setIsTruncated] = useState(false);
  const titleRef = useRef(null);
  const [areDocumentsParsed, setAreDocumentsParsed] = useState(false);

  // Function to check if text is truncated
  const checkTextTruncation = () => {
    if (titleRef.current) {
      const titleElement = titleRef.current as HTMLElement;
      const clone = titleElement.cloneNode(true) as HTMLElement;
      clone.style.position = "absolute";
      clone.style.visibility = "hidden";
      clone.style.whiteSpace = "nowrap";
      clone.style.width = "auto";
      document.body.appendChild(clone);
      const titleWidth = clone.scrollWidth;
      document.body.removeChild(clone);
      const containerWidth = titleElement.clientWidth;
      setIsTruncated(titleWidth > containerWidth);
    }
  };

  // Check text truncation on mount and when the window is resized
  useEffect(() => {
    checkTextTruncation();
    window.addEventListener("resize", checkTextTruncation);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("resize", checkTextTruncation);
    };
  }, []);

  const { language } = useLanguage();
  const locales = language === "fr" ? "fr-FR" : "en-US";

  const onProjectProgressChange = async (value: ProjectProgress) => {
    setProjectProgress(value);
    await SeevClient.project.updateProject(project.id, { progress: value });
  };

  const handleRemoveProject = async () => {
    setRemovingProject(true);
    await SeevClient.project.deleteProject(project.id);
    setRemoveProjectDialogOpen(false);
    setRemovingProject(false);
    navigate("/portfolio");
  };

  const scrollTopAndSetActive = (tab: string) => {
    setActiveTab(tab);
    document.querySelector(".main-content")?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleGenerateOutline = async () => {
    await SeevClient.project.writeOutline(project.id);
    setProject({ ...project, outlineStatus: "PARSING" });
  };

  const generateProposal = async () => {
    setIsProposalBeingGenerated(true);
    try {
      await SeevClient.project.generateProposal(project.id);
      setIsProposalReady(true);
    } catch (error) {
      toast({
        title: intl.formatMessage({
          id: "global.defaultErrorTitle",
        }),
        description: intl.formatMessage({
          id: "global.defaultErrorDescription",
        }),
        variant: "destructive",
      });
    } finally {
      setIsProposalBeingGenerated(false);
    }
  };

  const generateRequiredCompanyData = async () => {
    setIsRequiredCompanyDataBeingGenerated(true);
    try {
      await SeevClient.project.generateRequiredCompanyData(project.id);
    } catch (error) {
      toast({
        title: intl.formatMessage({
          id: "global.defaultErrorTitle",
        }),
        description: intl.formatMessage({
          id: "global.defaultErrorDescription",
        }),
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    setIsProposalReady(project.proposalStatus === "COMPLETED");
    setIsRequiredCompanyDataReady(
      project.requiredCompanyDataStatus === "PARSED",
    );
    setIsRequiredCompanyDataBeingGenerated(
      project.requiredCompanyDataStatus === "PARSING",
    );
  }, [project.proposalStatus, project.requiredCompanyDataStatus]);

  const downloadProposal = async () => {
    const response = await SeevClient.project.getProposal(project.id);
    const url = URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "proposal.docx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function generateBadges(project: Project) {
    const daysBetween =
      calculateDaysBetween(project.summary?.rfpApplicationDeadline) ?? 0;
    return [
      {
        id: 0,
        title: project.agency,
        content: project.agency,
        icon: Building2,
        color: "bg-pastel-gray",
      },
      {
        id: 1,
        title: (
          <FormattedMessage
            id="project.summaryFields.dueDateSpecification"
            values={{ days: daysBetween || "N/A" }}
          />
        ),
        content: daysBetween
          ? new Date(
              project.summary?.rfpApplicationDeadline || new Date(),
            ).toLocaleString(locales, {
              year: "numeric",
              month: "long",
              day: "numeric",
              weekday: "short",
              timeZone: "UTC",
            })
          : "N/A",
        icon: CalendarClock,
        color: calculateColorDays(daysBetween),
      },
    ].filter((b) =>
      !["GO_NO_GO", "NO_GO", "PROPOSAL"].includes(projectProgress)
        ? b.id !== 1
        : true,
    );
  }

  const outlineDocumentsKey = useMemo(
    () => JSON.stringify(project.outlineDocuments),
    [project.outlineDocuments],
  );
  const checkDocumentsParsing = useCallback(async () => {
    if (!project.outlineDocuments?.length) {
      setAreDocumentsParsed(false);
      return;
    }

    try {
      console.log("polling from checkDocumentsParsing");
      const fileStatuses = await Promise.all(
        project.outlineDocuments.map((doc) => SeevClient.file.getFile(doc)),
      );

      const allParsed = fileStatuses.every((file) => file.status === "PARSED");
      setAreDocumentsParsed(allParsed);

      if (!allParsed) {
        setTimeout(checkDocumentsParsing, 2000);
      }
    } catch (error) {
      console.error("Error checking document status:", error);
      setAreDocumentsParsed(false);
    }
    // To prevent unnecessary API calls when we are polling the project
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outlineDocumentsKey]);

  useEffect(() => {
    checkDocumentsParsing();
  }, [checkDocumentsParsing]);

  return (
    <div>
      <Toaster />
      <RemoveProjectDialog
        open={removeProjectDialogOpen}
        loading={removingProject}
        onClose={() => setRemoveProjectDialogOpen(false)}
        onConfirm={handleRemoveProject}
      />
      <ProjectSettingsDialog
        open={settingsDialogOpen}
        project={project}
        onClose={() => setSettingsDialogOpen(false)}
        onSave={onSave}
      />
      <div className="flex flex-wrap xl:flex-nowrap items-start justify-between gap-x-10 2xl:gap-x-20">
        <div ref={titleRef} className="w-full flex gap-2">
          <div>
            {/* Title */}
            <span
              className={`inline-flex relative ${isExpanded && "w-fit break-all !h-[33px] !overflow-hidden"}`}
            >
              <h2 className="text-2xl font-semibold tracking-tight">
                {project?.title}
              </h2>
              {/* Show expand button only if the text is truncated */}
              {isTruncated && (
                <span className="px-1 !bg-background">
                  <Ellipsis
                    className="rounded-lg mt-2.5 bg-gray-100 dark:bg-muted text-gray-400 cursor-pointer hover:text-muted-foreground !h-6 !w-8"
                    onClick={() => setIsExpanded(!isExpanded)}
                  />
                </span>
              )}
            </span>
            {/* Badges */}
            <div className="text-sm text-gray-700 flex flex-wrap items-center gap-2 my-4">
              <TooltipProvider>
                {generateBadges(project).map((badge, index) => (
                  <Tooltip delayDuration={150} key={index}>
                    <TooltipTrigger asChild>
                      <div
                        className={`${badge.color} flex items-center gap-2 px-2.5 py-1 rounded-lg`}
                      >
                        <badge.icon className="h-5 w-5" />
                        <p className="font-medium max-w-[220px] truncate">
                          {badge.title}
                        </p>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side="bottom" sideOffset={10}>
                      <div className="w-max max-w-[300px]">{badge.content}</div>
                    </TooltipContent>
                  </Tooltip>
                ))}
              </TooltipProvider>

              {/* Status Badge As DropDown */}
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <div
                    className={`${calculateProgressColor(projectProgress)} cursor-pointer flex items-center gap-2 px-2.5 py-1 rounded-lg`}
                  >
                    <Flag className="h-5 w-5" />
                    <p className="font-medium">
                      <FormattedMessage
                        id={
                          projectProgress
                            ? `project.projectProgress.${projectProgress}`
                            : "project.summaryFields.noSpecification"
                        }
                      />
                    </p>
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="center">
                  {[
                    "GO_NO_GO",
                    "PROPOSAL",
                    "SUBMITTED",
                    "WON",
                    "COMPLETED",
                    "NO_GO",
                    "CANCELED",
                    "LOST",
                  ].map((item) => (
                    <DropdownMenuItem
                      className={`m-0.5 cursor-pointer`}
                      key={item}
                      onClick={() => {
                        onProjectProgressChange(item as ProjectProgress);
                      }}
                    >
                      <div
                        className={`${calculateProgressColor(item as ProjectProgress)} h-4 w-4 rounded mr-1.5`}
                      />
                      <FormattedMessage
                        id={`project.projectProgress.${item}`}
                      />
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
              {(isProposalBeingGenerated || isProposalReady) && (
                <span className="bg-pastel-yellow text-sm text-gray-700 flex items-center gap-2 px-2.5 py-1 rounded-lg">
                  {isProposalBeingGenerated && (
                    <Loader className="h-5 w-5 animate-spin" />
                  )}
                  <p className="font-medium">
                    <FormattedMessage
                      id={
                        isProposalBeingGenerated
                          ? "project.summaryFields.generating"
                          : "project.summaryFields.proposalAvailable"
                      }
                    />
                  </p>
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-end flex-col gap-4">
          <div className="flex gap-2">
            <TabsList>
              <TabsTrigger
                onClick={() => scrollTopAndSetActive("dashboard")}
                value="dashboard"
              >
                <FormattedMessage id="project.tabs.dashboard" />
              </TabsTrigger>
              <TabsTrigger
                onClick={() => scrollTopAndSetActive("requirements")}
                value="requirements"
              >
                <FormattedMessage id="project.tabs.requirements" />
              </TabsTrigger>
              {featureFlags().enableCapture && (
                <TabsTrigger
                  onClick={() => scrollTopAndSetActive("qualifications")}
                  value="qualifications"
                >
                  <FormattedMessage id="project.tabs.qualifications" />
                </TabsTrigger>
              )}
              <TabsTrigger
                onClick={() => scrollTopAndSetActive("tableForms")}
                value="tableForms"
                disabled={!featureFlags().enableForms}
              >
                <FormattedMessage id="project.tabs.forms" />
              </TabsTrigger>
              <TabsTrigger
                onClick={() => scrollTopAndSetActive("proposal")}
                value="proposal"
                disabled={!featureFlags().enableProposal}
              >
                <FormattedMessage id="project.tabs.proposal" />
              </TabsTrigger>
            </TabsList>
            <div className="flex items-center space-x-2">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" size="icon">
                    <EllipsisVertical />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem onClick={() => setSettingsDialogOpen(true)}>
                    <Settings className="mr-2 h-4 w-4" />
                    <FormattedMessage id="project.settings" />
                  </DropdownMenuItem>
                  {isProposalReady && (
                    <DropdownMenuItem onClick={() => downloadProposal()}>
                      <CloudDownload className="mr-2 h-4 w-4" />
                      <FormattedMessage id="project.tabs.proposal" />
                    </DropdownMenuItem>
                  )}
                  <DropdownMenuItem
                    onClick={() => setRemoveProjectDialogOpen(true)}
                    className="text-destructive hover:!text-destructive"
                  >
                    <Trash2 className="mr-2 h-4 w-4" />
                    <FormattedMessage id="global.remove" />
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>

          {/* Outline Generation Buttons */}
          {activeTab === "proposal" && (
            <>
              {(project.outlineStatus === "NOT_PARSED" ||
                project.outlineStatus === "PARSE_FAILED") && (
                <Button
                  variant="default"
                  size="sm"
                  className="w-max mb-3"
                  disabled={
                    !project.outlineDocuments?.length || !areDocumentsParsed
                  }
                  onClick={() => handleGenerateOutline()}
                >
                  <WandSparkles className="mr-2 h-4 w-4" />
                  {project.outlineStatus === "NOT_PARSED" ? (
                    <FormattedMessage id="project.outline.generate" />
                  ) : (
                    <FormattedMessage id="project.outline.regenerate" />
                  )}
                </Button>
              )}
            </>
          )}
          {/* Generate Proposal Button */}
          {activeTab === "proposal" && project.outlineStatus === "PARSED" ? (
            <>
              {isProposalReady && (
                <Button
                  variant="default"
                  size="sm"
                  className="w-max mb-3"
                  onClick={() => downloadProposal()}
                >
                  <CloudDownload className="mr-2 h-4 w-4" />
                  <FormattedMessage id="project.tabs.proposal" />
                </Button>
              )}
              {!isRequiredCompanyDataReady && (
                <Button
                  variant="default"
                  size="sm"
                  className="w-max mb-3"
                  onClick={() => generateRequiredCompanyData()}
                  disabled={isRequiredCompanyDataBeingGenerated}
                >
                  {isRequiredCompanyDataBeingGenerated && (
                    <Loader className="h-4 w-4 animate-spin mr-2" />
                  )}
                  <FormattedMessage id="project.generateRequiredCompanyData" />
                </Button>
              )}
              {isRequiredCompanyDataReady && !isProposalReady && (
                <Button
                  variant="default"
                  size="sm"
                  className="w-max mb-3"
                  onClick={() => generateProposal()}
                  disabled={isProposalBeingGenerated}
                >
                  <WandSparkles className="mr-2 h-4 w-4" />
                  <FormattedMessage id="project.generateProposal" />
                </Button>
              )}
            </>
          ) : (
            // just to prevent layout shifting
            <div className="h-13"></div>
          )}
        </div>
      </div>
    </div>
  );
}

export { ToolBar };
