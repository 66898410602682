import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { FormattedMessage } from "react-intl";
import { Project, TableForm } from "@/types/entities/Project";
import { useState, useMemo, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  getFacetedRowModel,
  getFacetedUniqueValues,
} from "@tanstack/react-table";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import PDFExplorer from "@/modules/FileRenderer/explorers/PDFExplorer/PDFExplorer";

import { Button } from "@/components/ui/button";
import {
  ArrowUpDown,
  FileUp,
  FileIcon,
  Download,
  WandSparkles,
  Loader2,
  X,
} from "lucide-react";
import { ColumnDef } from "@tanstack/react-table";
import { ScrollArea } from "@/components/ui/scroll-area";
import { SeevClient } from "@/lib/SeevClient";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

export interface TableFormCardProps {
  project: Project;
  setProject: (project: Project) => void;
}

export default function TableFormCard({
  project,
  setProject,
}: TableFormCardProps) {
  const [isFilePreviewOpen, setIsFilePreviewOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pagesToRender, setPagesToRender] = useState<number[]>([]);
  const [isUploading, setIsUploading] = useState<Record<string, boolean>>({});

  const previewFile = useCallback(
    async (documentId: string, pageNumbers: number[]) => {
      const response = await SeevClient.file.downloadFile(documentId, true);
      if (!response) return;
      const fileURL = URL.createObjectURL(response);
      setPageNumber(pageNumbers[0]);
      setPagesToRender(pageNumbers);
      setPreviewUrl(fileURL);
      setIsFilePreviewOpen(true);
    },
    [],
  );

  const handleFileUpload = useCallback(
    async (tableForm: TableForm, files: FileList) => {
      setIsUploading((prev) => ({
        ...prev,
        [tableForm.id]: true,
      }));
      try {
        const uploadPromises = Array.from(files).map((file) =>
          SeevClient.file.uploadNewFile(file, true, "PAST_PROJECT_DOCUMENT"),
        );
        const documents = await Promise.all(uploadPromises);
        const supportingDocuments = documents.map((doc) => ({
          id: doc.id,
          name: doc.name,
        }));

        const updatedRegularForms = project.tablesForms?.map((tf) =>
          tf.id === tableForm.id && tableForm.source === "regular"
            ? {
                ...tf,
                supportingDocuments: [
                  ...(tf.supportingDocuments || []),
                  ...supportingDocuments,
                ],
              }
            : tf,
        );

        const updatedSeparateForms = project.tablesFormsSeparateFile?.map(
          (tf) =>
            tf.id === tableForm.id && tableForm.source === "separate"
              ? {
                  ...tf,
                  supportingDocuments: [
                    ...(tf.supportingDocuments || []),
                    ...supportingDocuments,
                  ],
                }
              : tf,
        );

        const updatedProject = await SeevClient.project.updateProject(
          project.id,
          {
            tablesForms: updatedRegularForms,
            tablesFormsSeparateFile: updatedSeparateForms,
          },
        );

        setProject({
          ...project,
          tablesForms: updatedProject.tablesForms,
          tablesFormsSeparateFile: updatedProject.tablesFormsSeparateFile,
        });

        // TODO: instead of 10 seconds, check if the document is parsed
        setTimeout(() => {
          setIsUploading((prev) => ({
            ...prev,
            [tableForm.id]: false,
          }));
        }, 10000);
      } catch (error) {
        console.error(error);
        setIsUploading((prev) => ({
          ...prev,
          [tableForm.id]: false,
        }));
      }
    },
    [project, setProject],
  );

  const downloadFile = useCallback(async (documentId: string) => {
    const fileInfo = await SeevClient.file.getFile(documentId);
    const response = await SeevClient.file.downloadFile(documentId);
    if (!response) return;
    const fileURL = URL.createObjectURL(response);
    const a = document.createElement("a");
    a.href = fileURL;
    a.download = fileInfo?.name;
    a.click();
  }, []);

  const fillTableForm = useCallback(
    async (id: string) => {
      try {
        const updatedProject = {
          ...project,
          tablesForms: project.tablesForms?.map((tf) =>
            tf.id === id
              ? { ...tf, fillingStatus: "IN_PROGRESS" as const }
              : tf,
          ),
          tablesFormsSeparateFile: project.tablesFormsSeparateFile?.map((tf) =>
            tf.id === id
              ? { ...tf, fillingStatus: "IN_PROGRESS" as const }
              : tf,
          ),
        };
        setProject(updatedProject);
        await SeevClient.project.fillTableForm(project.id, id);
      } catch (error) {
        const updatedProject = {
          ...project,
          tablesForms: project.tablesForms?.map((tf) =>
            tf.id === id ? { ...tf, fillingStatus: "FAILED" as const } : tf,
          ),
          tablesFormsSeparateFile: project.tablesFormsSeparateFile?.map((tf) =>
            tf.id === id ? { ...tf, fillingStatus: "FAILED" as const } : tf,
          ),
        };
        setProject(updatedProject);
      }
    },
    [project, setProject],
  );

  const handleRemoveDocument = useCallback(
    async (tableForm: TableForm, documentId: string) => {
      try {
        const updatedRegularForms = project.tablesForms?.map((tf) =>
          tf.id === tableForm.id && tableForm.source === "regular"
            ? {
                ...tf,
                supportingDocuments: tf.supportingDocuments?.filter(
                  (doc) => doc.id !== documentId,
                ),
              }
            : tf,
        );

        const updatedSeparateForms = project.tablesFormsSeparateFile?.map(
          (tf) =>
            tf.id === tableForm.id && tableForm.source === "separate"
              ? {
                  ...tf,
                  supportingDocuments: tf.supportingDocuments?.filter(
                    (doc) => doc.id !== documentId,
                  ),
                }
              : tf,
        );

        const updatedProject = await SeevClient.project.updateProject(
          project.id,
          {
            tablesForms: updatedRegularForms,
            tablesFormsSeparateFile: updatedSeparateForms,
          },
        );

        setProject({
          ...project,
          tablesForms: updatedProject.tablesForms,
          tablesFormsSeparateFile: updatedProject.tablesFormsSeparateFile,
        });
      } catch (error) {
        console.error("Failed to remove document:", error);
      }
    },
    [project, setProject],
  );

  const columns: ColumnDef<TableForm>[] = useMemo(
    () => [
      {
        accessorKey: "formOrTable",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            <FormattedMessage id="project.tableForm.fields.formOrTable" />
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => row.getValue("formOrTable"),
      },
      {
        accessorKey: "name",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            <FormattedMessage id="project.tableForm.fields.name" />
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) =>
          row.original.differentiation
            ? `${row.getValue("name")} (${row.original.differentiation})`
            : row.getValue("name"),
      },
      {
        accessorKey: "supportingDocuments",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            <FormattedMessage id="project.tableForm.fields.supportingDocuments" />
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          return row.original.supportingDocuments?.map((doc) => (
            <div key={doc.id} className="flex items-center  gap-2 mb-1">
              <span>{doc.name}</span>
              <Button
                variant="ghost"
                size="icon-sm"
                onClick={() => {
                  handleRemoveDocument(row.original, doc.id);
                }}
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
          ));
        },
      },
      // {
      //   accessorKey: "source",
      //   header: ({ column }) => (
      //     <Button
      //       variant="ghost"
      //       onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      //     >
      //       <FormattedMessage id="project.tableForm.fields.source" />
      //       <ArrowUpDown className="ml-2 h-4 w-4" />
      //     </Button>
      //   ),
      //   cell: ({ row }) => {
      //     return row.original.source === "separate" ? (
      //       <FormattedMessage id="global.yes" />
      //     ) : (
      //       <FormattedMessage id="global.no" />
      //     );
      //   },
      // },
      {
        id: "actions",
        header: () => (
          <div className="text-center">
            <FormattedMessage id="project.tableForm.fields.actions.title" />
          </div>
        ),
        cell: ({ row }) => (
          <div className="flex justify-center items-center gap-1">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon-sm"
                    onClick={() => {
                      previewFile(
                        row.original.documentId,
                        row.original.formTablePageNumbers,
                      );
                    }}
                  >
                    <FileIcon className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <FormattedMessage id="project.tableForm.fields.actions.preview" />
                </TooltipContent>
              </Tooltip>

              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon-sm"
                    onClick={() => {
                      const input = document.createElement("input");
                      input.type = "file";
                      input.multiple = true;
                      input.accept = ".pdf,.docx,.xlsx";
                      input.onchange = (e) => {
                        const files = (e.target as HTMLInputElement).files;
                        if (files?.length) {
                          handleFileUpload(row.original, files);
                        }
                      };
                      input.click();
                    }}
                  >
                    {isUploading[row.original.id] ? (
                      <Loader2 className="h-4 w-4 animate-spin" />
                    ) : (
                      <FileUp className="h-4 w-4" />
                    )}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <FormattedMessage id="project.tableForm.fields.actions.upload" />
                </TooltipContent>
              </Tooltip>

              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon-sm"
                    className="disabled:opacity-100 disabled:text-accent"
                    onClick={() => {
                      fillTableForm(row.original.id);
                    }}
                    disabled={
                      row.original.fillingStatus === "IN_PROGRESS" ||
                      isUploading[row.original.id] ||
                      !row.original.supportingDocuments?.length
                    }
                  >
                    {row.original.fillingStatus === "IN_PROGRESS" ? (
                      <Loader2 className="h-4 w-4 animate-spin" />
                    ) : (
                      <WandSparkles className="h-4 w-4" />
                    )}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <FormattedMessage id="project.tableForm.fields.actions.fill" />
                </TooltipContent>
              </Tooltip>

              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon-sm"
                    className="disabled:opacity-100 disabled:text-accent"
                    disabled={!row.original.filledDocumentId}
                    onClick={() => {
                      if (row.original.filledDocumentId) {
                        downloadFile(row.original.filledDocumentId);
                      }
                    }}
                  >
                    <Download className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <FormattedMessage id="project.tableForm.fields.actions.download" />
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        ),
      },
    ],
    [
      previewFile,
      handleFileUpload,
      fillTableForm,
      downloadFile,
      isUploading,
      handleRemoveDocument,
    ],
  );

  const tableData = useMemo(
    () => [
      ...(project.tablesForms || []).map((tf) => ({
        ...tf,
        source: "regular" as const,
      })),
      ...(project.tablesFormsSeparateFile || []).map((tf) => ({
        ...tf,
        source: "separate" as const,
      })),
    ],
    [project.tablesForms, project.tablesFormsSeparateFile],
  );

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });
  return (
    <Card className="w-full flex-1 h-[650px]">
      <CardHeader>
        <CardTitle>
          <FormattedMessage
            id="project.tableForm.title"
            defaultMessage="Tables and Forms"
          />
        </CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[550px]">
          <div className="space-y-4 mb-14">
            <div className="rounded-md border">
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <TableHead key={header.id} colSpan={header.colSpan}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                          </TableHead>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => (
                      <TableRow key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center"
                      >
                        <FormattedMessage id="project.tableForm.empty" />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </ScrollArea>
      </CardContent>
      <Dialog open={isFilePreviewOpen} onOpenChange={setIsFilePreviewOpen}>
        <DialogContent className="!min-w-[700px]">
          <PDFExplorer
            fileUrl={previewUrl}
            searchText={""}
            pageNumber={pageNumber}
            noBorder={true}
            pagesToRender={pagesToRender}
          />
        </DialogContent>
      </Dialog>
    </Card>
  );
}
