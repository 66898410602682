import { useState } from "react";
import { Project } from "@/types/entities/Project";
import { FormattedMessage } from "react-intl";
import { Loading } from "@/components/Loading/Loading";
import { Button } from "@/components/ui/button";
import TableFormCard from "../Cards/TableForm/TableForm";
import { SeevClient } from "@/lib/SeevClient";
import { LoaderCircle } from "lucide-react";

interface TableFormsProps {
  project: Project;
  setProject: (project: Project) => void;
}

function TableForms({ project, setProject }: TableFormsProps) {
  const [isRequestingTableFormParse, setIsRequestingTableFormParse] =
    useState(false);

  const handleTableFormParse = async () => {
    try {
      setIsRequestingTableFormParse(true);
      await SeevClient.project.extractTablesForms(project.id);
      setProject({
        ...project,
        tableFormStatus: "PARSING",
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsRequestingTableFormParse(false);
    }
  };
  return (
    <div className="flex flex-wrap xl:flex-nowrap h-full gap-4">
      {project.tableFormStatus === "NOT_PARSED" ||
      project.tableFormStatus === "PARSE_FAILED" ? (
        <div className="mt-12 flex flex-col w-full gap-6 text-center justify-center items-center bg-transparent text-4xl">
          <Button
            variant="default"
            className="min-w-64 rounded-full text-lg p-6 px-12"
            size="sm"
            onClick={() => {
              handleTableFormParse();
            }}
          >
            {isRequestingTableFormParse ? (
              <LoaderCircle className="h-5 w-5 mr-2 animate-spin" />
            ) : project.tableFormStatus === "NOT_PARSED" ? (
              <FormattedMessage id="project.tableForm.parse" />
            ) : (
              <FormattedMessage id="project.tableForm.reParse" />
            )}
          </Button>
        </div>
      ) : project.tableFormStatus === "PARSING" ? (
        <div className="relative h-full w-full mt-32">
          <div className="mt-24">
            <Loading messageId="project.tableForm.parsing" />
          </div>
        </div>
      ) : project.tableFormStatus === "PARSED" ? (
        <TableFormCard project={project} setProject={setProject} />
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default TableForms;
