import * as React from "react";
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  VisibilityState,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
  PaginationState,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { PortfolioProject } from "@/types/entities/Project";
import { LoaderCircle } from "lucide-react";
import { DataTablePagination } from "./components/data-table-pagination";
import ToolBar from "./components/tool-bar";
import createColumns from "./columns";
import { Loading } from "@/components/Loading/Loading";
import { ProjectProgress } from "@/types/entities/Project";
import { SeevClient } from "@/lib/SeevClient";

export interface ProjectTableProps {
  projects: PortfolioProject[];
  isLoading: boolean;
  onCreate: () => void;
  onUpdate: () => void;
  filterOnProgress: (progress: ProjectProgress | "ALL") => void;
}

export function ProjectTable({
  projects,
  isLoading,
  onCreate,
  onUpdate,
  filterOnProgress,
}: ProjectTableProps) {
  const [sorting, setSorting] = React.useState<SortingState>([
    { id: "createdAt", desc: true },
  ]);
  const [globalFilter, setGlobalFilter] = React.useState<string>();
  const [isUpdating, setIsUpdating] = React.useState<string>("");

  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({
      createdAt: false,
    });

  const [pagination, setPagination] = React.useState<PaginationState>({
    pageSize: 10,
    pageIndex: 0,
  });

  const onChangeProgress = React.useCallback(
    async (project_id: string, progress: ProjectProgress) => {
      if (isUpdating) return;
      setIsUpdating(project_id);
      const res = await SeevClient.project.updateProject(project_id, {
        progress: progress,
      });
      if (res?.id) {
        onUpdate();
      } else {
        setIsUpdating("");
      }
    },
    [isUpdating, onUpdate],
  );

  const columns = React.useMemo(() => {
    return createColumns(onChangeProgress);
  }, [onChangeProgress]);

  React.useEffect(() => {
    if (projects) {
      setIsUpdating("");
    }
  }, [projects]);

  const table = useReactTable({
    data: projects,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      globalFilter,
      pagination,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onPaginationChange: setPagination,
  });

  const navigate = useNavigate();

  const onRowClick = (projectId: string) => {
    navigate(`/project/${projectId}`);
  };

  return (
    <div className="flex flex-col mb-14 gap-2">
      <ToolBar
        setGlobalFilter={setGlobalFilter}
        onCreateProject={onCreate}
        filterOnProgress={filterOnProgress}
      />
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-40 text-center"
                >
                  <Loading messageId="portfolio.loading" />
                </TableCell>
              </TableRow>
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      onClickCapture={() => {
                        if (cell.column?.id !== "progress") {
                          onRowClick(row.original.id);
                        }
                      }}
                    >
                      {(isUpdating || isLoading) &&
                      isUpdating === row.original.id &&
                      cell.column?.id === "progress" ? (
                        <div className="w-max py-1.5 px-9 rounded-lg bg-muted flex items-center justify-center">
                          <LoaderCircle className="animate-spin h-5 w-5 text-muted-foreground" />
                        </div>
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  <FormattedMessage id="portfolio.empty" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  );
}

export default ProjectTable;
