import Dashboard from "./dashboard";

function AnalyticsPage() {
  return (
    <div className="flex h-full w-full flex-col gap-4">
      <div className="relative">
        <Dashboard />
      </div>
    </div>
  );
}

export default AnalyticsPage;
