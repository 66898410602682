import {
  OutlineCard,
  OutlineDetailCard,
  PastProposalUploadCard,
} from "../Cards/Outline";
import { OutlineSection } from "@/types/entities/Outline";
import { useState } from "react";
import { Project } from "@/types/entities/Project";
import { FormattedMessage } from "react-intl";
import { Loading } from "@/components/Loading/Loading";

interface OutlineProps {
  project: Project;
  setProject: (project: Project) => void;
}

function Proposal({ project, setProject }: OutlineProps) {
  const [selectedSection, setSelectedSection] = useState<OutlineSection | null>(
    project?.outline?.sections?.[0] || null,
  );

  const handleSectionClick = (section: OutlineSection) => {
    setSelectedSection(section);
  };

  return (
    <div className="flex flex-wrap xl:flex-nowrap h-full gap-4">
      {project.tableFormStatus !== "PARSED" ? (
        <div className="relative h-full w-full mt-32 flex justify-center items-center text-xl">
          <div className="mt-24">
            <FormattedMessage id="project.outline.tableFormRequired" />
          </div>
        </div>
      ) : project.outlineStatus === "NOT_PARSED" ? (
        <PastProposalUploadCard project={project} setProject={setProject} />
      ) : project.outlineStatus === "PARSING" ? (
        <div className="relative h-full w-full mt-32">
          <div className="mt-24">
            <Loading messageId="project.outline.parsing" />
          </div>
        </div>
      ) : project.outlineStatus === "PARSE_FAILED" ? (
        <div className="mt-12 flex flex-col gap-6 text-center justify-center items-center bg-transparent opacity-70 text-4xl">
          <FormattedMessage id="project.outline.parseFailed" />
        </div>
      ) : project.outlineStatus === "PARSED" ? (
        <div className="flex flex-col w-full gap-4">
          <div className="flex w-full gap-4 xl:flex-nowrap flex-wrap">
            <div className="flex w-full xl:w-3/5 h-full flex-col gap-4">
              <OutlineCard
                project={project}
                setProject={setProject}
                onSectionClick={handleSectionClick}
              />
            </div>
            <div className="flex-1 w-full xl:w-2/5 h-full flex flex-col gap-4">
              {selectedSection && (
                <OutlineDetailCard section={selectedSection} />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-12"></div>
      )}
    </div>
  );
}

export default Proposal;
