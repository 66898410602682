import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card";
import { FormattedMessage } from "react-intl";
import {
  Paragraph,
  TableOrForm,
  Placeholder,
  OutlineSection,
} from "@/types/entities/Outline";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export interface OutlineDetailCardProps {
  section: OutlineSection;
}

function ParagraphContent(paragraph: Paragraph) {
  return (
    <div className="flex flex-col gap-2">
      <p className="text-sm w-full px-4 py-2 !border leading-6 rounded-lg bg-background">
        {paragraph.description}
      </p>
    </div>
  );
}

function TableOrFormContent(tableOrForm: TableOrForm) {
  return (
    <p className="text-sm w-full px-4 py-2 !border leading-6 rounded-lg bg-background">
      {tableOrForm.name +
        (tableOrForm.differentiation
          ? ` (${tableOrForm.differentiation})`
          : "")}
    </p>
  );
}

function PlaceholderContent(placeholder: Placeholder) {
  return (
    <p className="text-sm w-full px-4 py-2 !border leading-6 rounded-lg bg-background">
      {`${placeholder.name} ${
        placeholder.differentiation ? `(${placeholder.differentiation})` : ""
      }`}
    </p>
  );
}
function Confidence() {
  return (
    <div className="confidence bg-[#FFF2C6] px-4 py-1 rounded-lg">
      <span className="text-sm">Confidence</span>
      <span className="ml-2 font-semibold">73%</span>
    </div>
  );
}

function WritingPlanSection({ section }: { section: OutlineSection }) {
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [selectedContentId, setSelectedContentId] = useState<string | null>(
    null,
  );

  const paragraphs = section.directContents.filter(
    (content) => content.type === "paragraph",
  );
  const tables = section.directContents.filter(
    (content) => content.type === "table",
  );
  const forms = section.directContents.filter(
    (content) => content.type === "form",
  );
  const placeholders = section.directContents.filter(
    (content) => content.type === "placeholder",
  );

  const contentTypes = [
    {
      type: "paragraph",
      count: paragraphs.length,
      label: "Paragraphs",
      bgColor: "bg-pastel-gray",
      contents: paragraphs,
    },
    {
      type: "table",
      count: tables.length,
      label: "Table",
      bgColor: "bg-pastel-green",
      contents: tables,
    },
    {
      type: "form",
      count: forms.length,
      label: "Forms",
      bgColor: "bg-pastel-blue",
      contents: forms,
    },
    {
      type: "placeholder",
      count: placeholders.length,
      label: "Files",
      bgColor: "bg-pastel-red",
      contents: placeholders,
    },
  ];

  return (
    <div className="flex gap-4">
      {/* Left side */}
      <div className="flex flex-col gap-2 min-w-[150px]">
        {contentTypes.map(({ type, count, label, bgColor }) => (
          <div
            key={type}
            className={`flex text-sm items-center gap-2 px-4 py-1 rounded-full ${bgColor} cursor-pointer text-gray-700
              ${selectedType === type ? "ring-2 ring-offset-2 ring-blue-400" : ""}`}
            onClick={() => {
              if (count === 0) return;
              setSelectedType(type === selectedType ? null : type);
              setSelectedContentId(null);
            }}
          >
            <span className="font-semibold">{count}</span>
            <span>{label}</span>
          </div>
        ))}
      </div>

      {/* Right side */}
      {selectedType && (
        <div className="flex-1">
          <Select
            value={selectedContentId || ""}
            onValueChange={(value) => setSelectedContentId(value)}
          >
            <SelectTrigger className="w-full mb-2">
              <SelectValue placeholder={`Select ${selectedType}`} />
            </SelectTrigger>
            <SelectContent>
              {contentTypes
                .find((ct) => ct.type === selectedType)
                ?.contents.map((content, index) => (
                  <SelectItem key={content.id} value={content.id}>
                    {content.type === "paragraph"
                      ? `Paragraph ${index + 1}`
                      : content.name}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>

          {/* Selected content display */}
          {selectedContentId && (
            <div className="mt-2">
              {(() => {
                const content = contentTypes
                  .find((ct) => ct.type === selectedType)
                  ?.contents.find((c) => c.id === selectedContentId);
                if (!content) return null;

                if (content.type === "paragraph")
                  return ParagraphContent(content as Paragraph);
                if (content.type === "table" || content.type === "form")
                  return TableOrFormContent(content as TableOrForm);
                if (content.type === "placeholder")
                  return PlaceholderContent(content as Placeholder);
              })()}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function OutlineDetailCard({ section }: OutlineDetailCardProps) {
  return (
    <Card className="h-[650px]">
      <ScrollArea className="h-[640px]">
        <CardHeader>
          <div className="flex justify-between items-center w-full">
            <CardTitle className="text-2xl font-bold">
              <FormattedMessage id="project.outline.detail.title" />
            </CardTitle>
            {false && <Confidence />}
          </div>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col gap-6">
            {/* Section Number & Title */}
            <div className="flex flex-col gap-1">
              <div className="bg-pastel-gray w-fit px-5 py-2 rounded-full">
                <h1 className="text-md text-gray-700">
                  {section.number}&nbsp;&nbsp;&nbsp;{section.title}
                </h1>
              </div>
            </div>

            {/* Section Objective */}
            <div className="flex flex-col gap-2">
              <h2 className="text-lg font-semibold border-b">
                <FormattedMessage id="project.outline.detail.sectionObjective" />
              </h2>
              <p className="text-sm leading-6">{section.description}</p>
            </div>

            {/* Section Writing Plan */}
            <div className="flex flex-col gap-4">
              <h2 className="text-lg font-semibold border-b">
                <FormattedMessage id="project.outline.detail.sectionWritingPlan" />
              </h2>
              <WritingPlanSection section={section} />
            </div>
          </div>
        </CardContent>
      </ScrollArea>
    </Card>
  );
}

export default OutlineDetailCard;
