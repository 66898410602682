import {
  // CLIENT_CATEGORIES,
  Opportunity,
  OpportunityDocumentStatus,
  getRegionName,
} from "@/types/entities/Opportunity";
import { ColumnDef, Row, Table, TableMeta } from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { FormattedMessage } from "react-intl";
import {
  ArrowUpDown,
  Archive,
  ArchiveX,
  Forward,
  Plus,
  ExternalLink,
  CircleCheckBig,
  CircleMinus,
  CirclePlus,
} from "lucide-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SeevClient } from "@/lib/SeevClient/SeevClient";
import { useState } from "react";
import ShareOpportunityDialog from "./ShareOpportunityDialog";
import AddProjectDialog from "@/modules/MainNav/components/AddProjectDialog";
import React from "react";
import { useToast } from "@/hooks/ToastHook/use-toast";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
// import { FilterDropdownBtn } from "@/components/ui/filter-dropdown-btn";
import { calculateDaysBetween } from "@/utils/progress-color";
import { calculateColorDays } from "@/utils/progress-color";

interface ExtendedTableMeta extends TableMeta<Opportunity> {
  currentStatus: OpportunityDocumentStatus;
  refetchOpportunities: (status: OpportunityDocumentStatus) => Promise<void>;
}

function UseCellActions(
  row: Row<Opportunity>,
  table: Table<Opportunity>,
  refetchOpportunities: (status: OpportunityDocumentStatus) => Promise<void>,
) {
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const queryClient = useQueryClient();
  const currentStatus = (table.options.meta as ExtendedTableMeta)
    ?.currentStatus;

  const updateStatusMutation = useMutation({
    mutationFn: async (newStatus: OpportunityDocumentStatus) => {
      await SeevClient.opportunities.updateStatus(row.original.id, newStatus);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["opportunities"] });

      // First fetch the current status to ensure UI is updated
      await refetchOpportunities(currentStatus);
    },
  });

  const handleArchiveClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    const newStatus =
      currentStatus === OpportunityDocumentStatus.ARCHIVED
        ? OpportunityDocumentStatus.INBOX
        : OpportunityDocumentStatus.ARCHIVED;
    updateStatusMutation.mutate(newStatus);
  };

  const handleShareClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShareDialogOpen(true);
  };

  return {
    updateStatusMutation,
    handleArchiveClick,
    currentStatus,
    handleShareClick,
    shareDialogOpen,
    setShareDialogOpen,
  };
}

const OpportunityActionsCell = ({
  row,
  table,
}: {
  row: Row<Opportunity>;
  table: Table<Opportunity>;
}) => {
  const [addProjectDialogOpen, setAddProjectDialogOpen] = useState(false);
  const { toast } = useToast();

  const handleAddProjectClick = () => {
    setAddProjectDialogOpen(true);
  };

  const {
    updateStatusMutation,
    handleArchiveClick,
    currentStatus,
    handleShareClick,
    shareDialogOpen,
    setShareDialogOpen,
  } = UseCellActions(
    row,
    table,
    (table.options.meta as ExtendedTableMeta)?.refetchOpportunities,
  );

  const handleProjectCreated = async (success: boolean) => {
    if (success) {
      try {
        await updateStatusMutation.mutateAsync(
          OpportunityDocumentStatus.PORTFOLIO,
        );
        toast({
          title: "Project Created",
          description: `The project: ${row.original.title} was successfully created.`,
          variant: "success",
        });
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to update opportunity status",
          variant: "destructive",
        });
      }
    }
    setAddProjectDialogOpen(false);
  };

  return (
    <>
      <div className="justify-center text-center flex items-center gap-1.5">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="secondary"
                size="icon-sm"
                onClick={(event) => {
                  event.stopPropagation();
                  window.open(
                    row.original.url,
                    "_blank",
                    "noopener,noreferrer",
                  );
                }}
              >
                <ExternalLink className="!text-foreground !h-4 !w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <FormattedMessage id="opportunities.actions.openExternal" />
            </TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="secondary"
                size="icon-sm"
                onClick={handleAddProjectClick}
              >
                <Plus className="!text-foreground !h-4 !w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <FormattedMessage id="opportunities.actions.createProject" />
            </TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="secondary"
                size="icon-sm"
                onClick={handleShareClick}
              >
                <Forward className="!text-foreground !h-4 !w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <FormattedMessage id="opportunities.actions.share" />
            </TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="secondary"
                size="icon-sm"
                onClick={handleArchiveClick}
              >
                {currentStatus === OpportunityDocumentStatus.ARCHIVED ? (
                  <ArchiveX className="!text-foreground !h-4 !w-4" />
                ) : (
                  <Archive className="!text-foreground !h-4 !w-4" />
                )}
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <FormattedMessage
                id={
                  currentStatus === OpportunityDocumentStatus.ARCHIVED
                    ? "opportunities.actions.unarchive"
                    : "opportunities.actions.archive"
                }
              />
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <ShareOpportunityDialog
        opportunity={row.original}
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
      />
      <AddProjectDialog
        onCreate={handleProjectCreated}
        initialValues={{
          title: row.original.title,
          agency: row.original.entity,
        }}
        defaultOpen={addProjectDialogOpen}
      />
    </>
  );
};

export const columns: ColumnDef<Opportunity>[] = [
  {
    accessorKey: "title",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <FormattedMessage id="opportunities.fields.title" />
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => <div>{row.getValue("title")}</div>,
  },
  {
    accessorKey: "entity",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <FormattedMessage id="opportunities.fields.agency" />
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => <div>{row.getValue("entity")}</div>,
  },
  {
    accessorKey: "areaId",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <FormattedMessage id="opportunities.fields.region" />
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      const region = getRegionName(row.getValue("areaId"));
      if (region === "Unknown") {
        return <div className="flex justify-center">-</div>;
      }
      return (
        <div className="flex justify-center">
          <FormattedMessage id={`opportunities.regions.canada.${region}`} />
        </div>
      );
    },
  },
  // {
  //   accessorKey: "clientCategory",
  //   filterFn: (row, columnId, filterValues: string[]) => {
  //     const clientCategory = row.getValue(columnId) as string;
  //     if (!filterValues?.length) return true;
  //     return filterValues.includes(clientCategory);
  //   },
  //   header: ({ column }) => (
  //     <FilterDropdownBtn
  //       column={column}
  //       values={CLIENT_CATEGORIES}
  //       labelId="opportunities.fields.clientCategory"
  //       translationPrefix="opportunities.clientCategory"
  //     />
  //   ),
  //   cell: ({ row }) => {
  //     const category = row.getValue("clientCategory") as string;

  //     return (
  //       <div>
  //         {category ? (
  //           <FormattedMessage id={`opportunities.clientCategory.${category}`} />
  //         ) : (
  //           "-"
  //         )}
  //       </div>
  //     );
  //   },
  // },
  {
    accessorKey: "deadline",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex justify-center"
        >
          <FormattedMessage id="opportunities.fields.deadline" />
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) =>
      row.original.endDate ? (
        <div className="flex justify-center">
          <div
            className={`${calculateColorDays(
              calculateDaysBetween(row.original.endDate) ?? 0,
            )}
          w-max text-gray-700 px-2.5 py-1 rounded-lg text-center`}
          >
            {row.original.endDate.split("T")[0]}
          </div>
        </div>
      ) : (
        <div className="text-center">-</div>
      ),
  },
  {
    accessorKey: "recommendation",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex justify-center"
        >
          <FormattedMessage id="opportunities.recommendation" />
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    sortingFn: (rowA, rowB) => {
      const recommendationOrder = { yes: 1, maybe: 2, no: 3, undefined: 4 };
      const valueA = rowA.getValue("recommendation") as string | undefined;
      const valueB = rowB.getValue("recommendation") as string | undefined;

      return (
        (recommendationOrder[valueA as keyof typeof recommendationOrder] || 4) -
        (recommendationOrder[valueB as keyof typeof recommendationOrder] || 4)
      );
    },
    cell: ({ row }) => {
      const recommendation = row.getValue("recommendation");

      if (!recommendation) return <div className="flex justify-center">-</div>;

      const iconMap = {
        yes: <CircleCheckBig className="text-[#22C55E] h-5 w-5" />,
        no: <CirclePlus className="text-[#EF4444] h-5 w-5 rotate-45" />,
        maybe: <CircleMinus className="text-[#FBBF24] h-5 w-5" />,
      };

      return (
        <div className="flex justify-center">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                {iconMap[recommendation as keyof typeof iconMap]}
              </TooltipTrigger>
              <TooltipContent>
                <FormattedMessage
                  id={`opportunities.recommendation_type.${recommendation}`}
                />
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      );
    },
  },
  {
    accessorKey: "actions",
    header: () => {
      return (
        <div className="text-center">
          <FormattedMessage id="opportunities.fields.action" />
        </div>
      );
    },
    cell: OpportunityActionsCell,
  },
  {
    accessorKey: "createdAt",
  },
];
