import React, { useCallback, useMemo } from "react";
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  VisibilityState,
  PaginationState,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { FormattedMessage } from "react-intl";
import { DataTablePagination } from "./components/data-table-pagination";
import ToolBar from "./components/tool-bar";
import { Loading } from "@/components/Loading/Loading";
import FileEntity, { SemanticSearchFileEntity } from "@/types/entities/File";
import createColumns from "./columns";
import RemoveFileDialog from "./components/DeleteFileDialog";
import { SeevClient } from "@/lib/SeevClient";

export interface FileTableProps {
  files: FileEntity[];
  filteredFiles: SemanticSearchFileEntity[] | null;
  isLoading: boolean;
  semanticSearch: (text: string) => void;
  onCreate: () => void;
  onDelete: (file: FileEntity) => void;
  selected: FileEntity | null;
  onSelect: (file: FileEntity) => void;
  onPreview: (file: FileEntity) => void;
}

export function FileTable({
  files,
  filteredFiles,
  isLoading,
  semanticSearch,
  onCreate,
  onDelete,
  selected,
  onSelect,
  onPreview,
}: FileTableProps) {
  const [sorting, setSorting] = React.useState<SortingState>([
    { id: "createdAt", desc: true },
  ]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [removeFile, setRemoveFile] = React.useState<FileEntity | null>(null);
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({
      createdAt: false,
      status: false,
    });

  const [pagination, setPagination] = React.useState<PaginationState>({
    pageSize: 10,
    pageIndex: 0,
  });

  const handleDelete = useCallback((file: FileEntity) => {
    setRemoveFile(file);
  }, []);

  const handleDownload = useCallback(async (file: FileEntity) => {
    const response = await SeevClient.file.downloadFile(file.id);
    if (!response) return;
    const fileURL = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const columns = useMemo(() => {
    return createColumns(handleDownload, handleDelete, onPreview);
  }, [handleDownload, handleDelete, onPreview]);

  // Sorting function for files when there are filteredFiles
  const sortedData = useMemo(() => {
    if (filteredFiles) {
      return filteredFiles.sort((a, b) => b.score - a.score);
    }
    return files;
  }, [filteredFiles, files]);

  // Override sorting behavior if filteredFiles exist
  const effectiveSorting = useMemo(() => {
    if (filteredFiles) {
      return []; // Diable sorting if filteredFiles exist
    }
    return sorting;
  }, [filteredFiles, sorting]);

  const table = useReactTable({
    data: sortedData,
    columns,
    state: {
      sorting: effectiveSorting,
      columnVisibility,
      rowSelection,
      pagination,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onPaginationChange: setPagination,
  });

  return (
    <div className="flex flex-col space-y-4 w-full mb-14">
      <ToolBar semanticSearch={semanticSearch} onCreateFile={onCreate} />
      <div className="rounded-md border w-full">
        <Table className="w-full">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-40 text-center"
                >
                  <Loading messageId="fileManager.loading" />
                </TableCell>
              </TableRow>
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  onClick={() => {
                    if (filteredFiles) {
                      if (selected?.id === row.original.id) {
                        return;
                      }
                    } else {
                      onSelect(row.original);
                    }
                  }}
                  className={
                    selected?.id === row.original.id
                      ? "bg-primary/50 hover:bg-primary/50"
                      : ""
                  }
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  <FormattedMessage id="fileManager.empty" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
      <RemoveFileDialog
        open={!!removeFile}
        onClose={() => setRemoveFile(null)}
        loading={isLoading}
        onConfirm={async () => {
          if (!removeFile) return;
          await onDelete(removeFile);
          setRemoveFile(null);
        }}
      />
    </div>
  );
}

export default FileTable;
