import { Card, CardContent } from "@/components/ui/card";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "@/components/ui/button";
import { Search, Loader2, X, PlusIcon } from "lucide-react";
import { TrashIcon } from "@radix-ui/react-icons";
import { useState, useEffect, useRef } from "react";
import { FileUploadZone } from "@/components/UploadZone/UploadZone";
import { SeevClient } from "@/lib/SeevClient";
import FileEntity, { SemanticSearchFileEntity } from "@/types/entities/File";
import { getExtImage } from "@/utils/progress-color";
import { Project } from "@/types/entities/Project";
import { Input } from "@/components/ui/input";
import { DateTime } from "luxon";
import useDebounce from "@/utils/debounce";
import { Dialog } from "@/components/ui/dialog";
import { DialogContent } from "@/components/ui/dialog";
import PDFExplorer from "@/modules/FileRenderer/explorers/PDFExplorer/PDFExplorer";

function PastProposalUploadCard({
  project,
  setProject,
}: {
  project: Project;
  setProject: (project: Project) => void;
}) {
  const intl = useIntl();
  const [selectedFiles, setSelectedFiles] = useState<
    FileEntity[] | SemanticSearchFileEntity[]
  >([]);
  const [parsingFiles, setParsingFiles] = useState<Record<string, boolean>>({});
  const [isLoadingInitialFiles, setIsLoadingInitialFiles] = useState(true);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<
    SemanticSearchFileEntity[]
  >([]);
  const [previewFile, setPreviewFile] = useState<
    FileEntity | SemanticSearchFileEntity | null
  >(null);
  const [isFilePreviewOpen, setIsFilePreviewOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const searchAbortController = useRef<AbortController | null>(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);
  const [isInputExpanded, setIsInputExpanded] = useState(false);

  useEffect(() => {
    const loadInitialFiles = async () => {
      if (!project.outlineDocuments?.length) {
        setIsLoadingInitialFiles(false);
        return;
      }

      try {
        const filePromises = project.outlineDocuments.map((fileId) =>
          SeevClient.file.getFile(fileId),
        );
        console.log("filePromises", filePromises);
        const files = await Promise.all(filePromises);
        setSelectedFiles(files.filter(Boolean) as FileEntity[]);
      } catch (error) {
        console.error("Error loading initial files:", error);
      } finally {
        setIsLoadingInitialFiles(false);
      }
    };

    loadInitialFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef]);

  const pollFileStatus = async (fileId: string) => {
    try {
      const file = await SeevClient.file.getFile(fileId);
      if (file && file.status === "PARSED") {
        setParsingFiles((prev) => ({ ...prev, [fileId]: false }));
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error polling file status:", error);
      return false;
    }
  };

  const handleFilePreview = async (file: SemanticSearchFileEntity) => {
    const response = await SeevClient.file.downloadFile(file.documentId, true);
    if (!response) return;
    const fileURL = URL.createObjectURL(response);
    setPreviewFile({
      ...file,
      url: fileURL,
    });
    setIsFilePreviewOpen(true);
    if ("text" in file && "pageNumber" in file) {
      setSearchText(file.text);
      setPageNumber(file.pageNumber);
    }
  };

  const handleFileUpload = async (files: File[]) => {
    if (!files.length) return;

    const file = files[0];

    const doc = await SeevClient.file.uploadNewFile(
      file,
      true,
      "PAST_PROPOSAL",
      "UPDATE_OUTLINE_DOCUMENTS",
      project.id,
    );

    setProject({
      ...project,
      outlineDocuments: [doc.id],
    });

    setSelectedFiles([doc]);
    setParsingFiles({ [doc.id]: true });

    const pollInterval = setInterval(async () => {
      const isParsed = await pollFileStatus(doc.id);
      if (isParsed) {
        clearInterval(pollInterval);
      }
    }, 2000);
  };

  const updateOutlineDocuments = async (file: SemanticSearchFileEntity) => {
    await SeevClient.project.updateProject(project.id, {
      outlineDocuments: [file.documentId],
    });
    setProject({
      ...project,
      outlineDocuments: [file.documentId],
    });
    setSelectedFiles([file]);
    setIsFilePreviewOpen(false);
  };

  const removeFile = async (index: number) => {
    console.log("index", index);
    await SeevClient.project.updateProject(project.id, {
      outlineDocuments: project.outlineDocuments?.filter((_, i) => i !== index),
    });
    setSelectedFiles((files) => files.filter((_, i) => i !== index));
    setProject({
      ...project,
      outlineDocuments: project.outlineDocuments?.filter((_, i) => i !== index),
    });
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchQuery(value);
    if (!value) {
      setSearchResults([]);
      setIsDropdownOpen(false);
    } else {
      setIsDropdownOpen(true);
      fetchSearchResults(value);
    }
  };

  const fetchSearchResults = useDebounce(async (query: string) => {
    if (!query) return;
    setSearchLoading(true);
    if (searchAbortController.current) {
      searchAbortController.current.abort();
    }
    searchAbortController.current = new AbortController();
    try {
      const searchResponse = await SeevClient.file.semanticSearchFiles(
        query,
        searchAbortController.current.signal,
        "PAST_PROPOSAL",
      );
      setSearchResults(
        searchResponse.sort((a, b) => b?.score - a?.score)?.slice(0, 5),
      );
    } finally {
      setSearchLoading(false);
    }
  }, 500);

  return (
    <Card className="w-full h-[650px]">
      <CardContent className="flex flex-col items-center p-6 pt-20">
        <h2 className="text-2xl text-gray-500">
          <FormattedMessage
            id="project.outline.uploadPastProposal.getStarted"
            defaultMessage="Get started by finding a relevant past proposal Word document."
          />
        </h2>

        <div className="flex flex-col items-center mt-20 w-full max-w-lg gap-6">
          {!isSearchActive ? (
            <Button
              variant="default"
              className="w-full max-w-sm rounded-full text-base py-0"
              size="sm"
              onClick={() => setIsSearchActive(true)}
            >
              <Search className="mr-2 h-5 w-5" />
              <FormattedMessage
                id="project.outline.uploadPastProposal.browseKnowledge"
                defaultMessage="Browse Knowledge"
              />
            </Button>
          ) : (
            <div
              ref={searchRef}
              className="relative w-full max-w-xl"
              onClickCapture={() => {
                if (searchResults?.length) {
                  setIsDropdownOpen(true);
                }
              }}
            >
              <div
                className={`
                transition-all duration-300 ease-out
                ${isInputExpanded ? "w-full" : "w-[384px]"}
                mx-auto
              `}
              >
                <Search className="absolute ml-4 mt-2.5 h-5 w-5 text-muted-foreground" />
                <Input
                  placeholder="Search..."
                  className={`
                    pl-12 !outline-none focus-visible:!ring-0 focus-visible:!ring-offset-0 
                    !border-none !h-[40px] bg-muted transition-colors duration-200 w-full
                    ${isDropdownOpen && !searchLoading && searchResults.length ? "!rounded-t-[1.7rem]" : "!rounded-full"}
                  `}
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  onFocus={() => setIsInputExpanded(true)}
                  onBlur={() => !searchQuery && setIsInputExpanded(false)}
                />
                {searchQuery && (
                  <X
                    className="absolute right-4 top-1/2 h-5 w-5 -translate-y-1/2 text-muted-foreground cursor-pointer"
                    onClick={() => {
                      setSearchQuery("");
                      setSearchResults([]);
                      setIsInputExpanded(false);
                      if (searchAbortController.current) {
                        searchAbortController.current.abort();
                      }
                    }}
                  />
                )}
                {searchLoading && (
                  <div className="bg-muted z-30 relative w-[calc(100%-2rem)] mx-auto -mt-0.5 h-[2px] rounded-full overflow-hidden">
                    <div className="absolute top-0 left-0 w-full h-full bg-primary animate-line-progress" />
                  </div>
                )}
                {isDropdownOpen &&
                  !searchLoading &&
                  searchResults.length > 0 && (
                    <div className="absolute -mt-0.5 w-full !bg-background !border-t !border-t-muted drop-shadow-md !rounded-b-[1.7rem] max-h-[800px] overflow-y-auto z-20 transition-colors duration-200">
                      <ul>
                        {searchResults.map((result, index) => (
                          <li
                            key={index}
                            onClick={() => handleFilePreview(result)}
                            className="flex justify-between gap-4 px-6 py-2 last:!pb-3 hover:bg-muted transition-all duration-200 cursor-pointer"
                          >
                            <span className="flex w-2/3 items-center gap-2">
                              <img
                                src={getExtImage(result.extension)}
                                className="h-4"
                                alt="ext"
                              />
                              <span className="flex w-full flex-col gap-0.5">
                                <span className="text-sm line-clamp-2">
                                  {result.name}
                                </span>
                                <span className="!font-light text-xs text-muted-foreground">
                                  {result.typeName}
                                </span>
                              </span>
                            </span>
                            <span className="text-muted-foreground text-xs w-max">
                              {DateTime.fromISO(result.updatedAt).toFormat(
                                "MMM d, yyyy",
                              )}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
              </div>
            </div>
          )}

          {/* Preview File */}
          <Dialog open={isFilePreviewOpen} onOpenChange={setIsFilePreviewOpen}>
            <DialogContent className="!min-w-[700px]">
              <Button
                className="flex items-center gap-2 max-w-sm mx-auto"
                variant="default"
                size="sm"
                onClick={async () => {
                  if (!previewFile || !("documentId" in previewFile)) return;
                  await updateOutlineDocuments(previewFile);
                }}
              >
                <PlusIcon className="w-4 h-4" />
                <FormattedMessage
                  id="project.outline.uploadPastProposal.addFileToOutline"
                  defaultMessage="Add file"
                />
              </Button>
              <PDFExplorer
                fileUrl={previewFile?.url}
                searchText={searchText}
                pageNumber={pageNumber}
                noBorder={true}
              />
            </DialogContent>
          </Dialog>

          <div className="text-muted-foreground/50 text-sm font-bold">
            <FormattedMessage
              id="project.outline.uploadPastProposal.or"
              defaultMessage="OR"
            />
          </div>

          <FileUploadZone
            className="w-full"
            onUpload={handleFileUpload}
            description={intl.formatMessage({
              id: "global.dragAndDrop",
            })}
            options={{
              multiple: false,
              accept: { "application/msword": [".docx"] },
            }}
          />
        </div>

        {isLoadingInitialFiles ? (
          <div className="w-full max-w-lg flex flex-col items-center mt-14">
            <Loader2 className="h-6 w-6 animate-spin text-muted-foreground" />
            <div className="text-sm text-muted-foreground mt-2">
              <FormattedMessage
                id="project.outline.uploadPastProposal.loadingFiles"
                defaultMessage="Loading files..."
              />
            </div>
          </div>
        ) : (
          selectedFiles.length > 0 && (
            <div className="w-full max-w-lg flex flex-col items-center mt-20">
              <div className="text-sm text-gray-500 mb-2 text-center">
                <FormattedMessage
                  id="project.outline.uploadPastProposal.selectedFiles"
                  defaultMessage="Selected File"
                />
              </div>
              <div className="space-y-1 w-full">
                {selectedFiles.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between bg-muted/30 rounded pl-3"
                  >
                    <div className="flex items-center gap-2">
                      <img
                        src={getExtImage(file.extension)}
                        className="h-4"
                        alt="ext"
                      />
                      <span className="text-sm">{file.name}</span>
                      {parsingFiles[file.id] && (
                        <Loader2 className="h-4 w-4 animate-spin text-muted-foreground" />
                      )}
                    </div>
                    <div className="flex space-x-2">
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => removeFile(index)}
                      >
                        <TrashIcon className="w-4 h-4" />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )
        )}
      </CardContent>
    </Card>
  );
}

export default PastProposalUploadCard;
