import axios, { AxiosError } from "axios";
import { Organization } from "@/types/entities/Organization";
import { Portfolio } from "@/types/entities/Portfolio";
import { recursiveCamelize, recursiveSnakify, JsonValue } from "../utils";
import { User } from "@/types/User";
import { TokenManager } from "./TokenManager";

class AuthManager {
  private baseURL: string;

  private tokenManager: TokenManager;
  private refreshTimeout: NodeJS.Timeout | null = null;

  private onLoginCallbacks: ((user: User) => void)[] = [];
  private onLogoutCallbacks: (() => void)[] = [];

  private organizationKey = "organization_id";
  public organizationId: string = "";

  public portFolioKey = "portfolio_id";
  public portfolioId: string = "";

  private userKey = "user_info";
  private user: User | null = null;

  constructor(baseURL: string, tokenManager: TokenManager) {
    this.baseURL = baseURL;
    this.tokenManager = tokenManager;
    this.setRefreshTimeout(this.tokenManager.getAuthToken().expiresAt);
    this.loadUser();
    this.loadOrganization();
    this.loadPortfolio();
  }

  public async request<T>(
    method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any,
    responseType: "json" | "arraybuffer" = "json", // Default to 'json'
    abortSignal?: AbortSignal | undefined,
  ): Promise<T> {
    try {
      const snakifiedData = data ? recursiveSnakify(data) : undefined;
      const response = await axios.request<JsonValue>({
        method,
        url: this.baseURL + url,
        data: snakifiedData,
        responseType, // Use the responseType parameter
        signal: abortSignal,
        headers: {
          Authorization: `Bearer ${this.tokenManager.getAuthToken().token}`,
        },
      });
      if (responseType === "json") {
        return recursiveCamelize(response.data) as T;
      }
      return response.data as T;
    } catch (error: unknown) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 401) {
        await this.refreshAuthToken();
        return this.request(method, url, data, responseType);
      }

      throw error;
    }
  }

  public async login(username: string, password: string): Promise<User> {
    try {
      const response = await axios.post(`${this.baseURL}/auth/login/`, {
        username,
        password,
      });

      this.tokenManager.setTokens(
        {
          token: response.data.access,
          expiresAt: response.data.tokenExpiresAt,
        },
        {
          token: response.data.refresh,
          expiresAt: response.data.refreshTokenExpiresAt,
        },
      );

      const user = {
        firstName: response.data.firstName || "",
        lastName: response.data.lastName || "",
        email: response.data.email || "",
        id: response.data.id || "",
      };

      this.setUser(user);
      await this.setOrganization();
      await this.setPortfolio();
      this.setRefreshTimeout(response.data.refreshTokenExpiresAt);
      this.onLoginCallbacks.forEach((callback) => callback(user));

      return user;
    } catch (error) {
      await this.logout();
      throw error;
    }
  }

  public async forgetPassword(email: string) {
    await axios.post(`${this.baseURL}/auth/reset-password/`, {
      email,
    });
  }

  public async resetPassword(url: string, password: string) {
    await axios.post(`${this.baseURL}${url}`, {
      new_password: password,
    });
  }

  private clear() {
    this.tokenManager.clear();

    this.user = null;
    localStorage.removeItem(this.userKey);

    this.organizationId = "";
    localStorage.removeItem(this.organizationKey);

    this.portfolioId = "";
    localStorage.removeItem(this.portFolioKey);

    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
  }

  public async signUp(signUpData: {
    organizationName: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }): Promise<number> {
    try {
      const snakifiedData = signUpData
        ? recursiveSnakify(signUpData)
        : undefined;

      const response = await axios.post(
        `${this.baseURL}/auth/signup/`,
        snakifiedData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (response.status === 201) {
        return response.status;
      }

      throw new Error(`Signup failed with status: ${response.status}`);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 409) {
          throw new Error("user_already_exists");
        }
      }
      throw error;
    }
  }

  public async logout() {
    this.clear();

    this.onLogoutCallbacks.forEach((callback) => {
      callback();
    });
  }

  public async loadUser() {
    const user = localStorage.getItem(this.userKey);

    if (user) {
      this.user = JSON.parse(user);
    }
  }

  public setUser(user: User) {
    this.user = user;
    localStorage.setItem(this.userKey, JSON.stringify(user));
  }

  public getUser() {
    return this.user;
  }

  private async refreshAuthToken() {
    try {
      const response = await axios.post(`${this.baseURL}/auth/refresh/`, {
        refresh: this.tokenManager.getRefreshToken().token,
      });

      this.tokenManager.setAuthToken({
        token: response.data.access,
        expiresAt: response.data.tokenExpiresAt,
      });

      this.setRefreshTimeout(response.data.refreshTokenExpiresAt);
    } catch (error) {
      this.logout();
      throw error;
    }
  }

  private setRefreshTimeout(refreshTokenExpiresAt: number) {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = null;
    }

    if (refreshTokenExpiresAt > Date.now()) {
      // Refresh the token halfway through it's expiration time
      this.refreshTimeout = setTimeout(
        () => {
          this.refreshAuthToken();
        },
        (refreshTokenExpiresAt - Date.now()) * 0.5,
      );
    }
  }

  public async onLogin(callback: (user: User) => void) {
    this.onLoginCallbacks.push(callback);
  }

  public async onLogout(callback: () => void) {
    this.onLogoutCallbacks.push(callback);
  }

  private async setOrganization() {
    try {
      const organizations = await this.request<Organization[]>(
        "GET",
        "/organizations/",
      );
      this.organizationId = organizations.length > 0 ? organizations[0].id : "";
      localStorage.setItem(this.organizationKey, this.organizationId);
    } catch (error) {
      console.error(error);
    }
  }

  private loadOrganization() {
    this.organizationId = localStorage.getItem(this.organizationKey) || "";
  }

  public getOrganizationId() {
    return this.organizationId;
  }

  private async setPortfolio() {
    try {
      const portfolios = await this.request<Portfolio[]>(
        "GET",
        `/organizations/${this.organizationId}/portfolios/`,
      );
      this.portfolioId = portfolios.length > 0 ? portfolios[0].id : "";
      localStorage.setItem(this.portFolioKey, this.portfolioId);
    } catch (error) {
      console.error(error);
    }
  }

  private loadPortfolio() {
    this.portfolioId = localStorage.getItem(this.portFolioKey) || "";
  }

  public getPortfolioId() {
    return this.portfolioId;
  }
}

export { AuthManager };
