import {
  Opportunity,
  OpportunityDocumentStatus,
} from "@/types/entities/Opportunity";
import { AuthManager } from "../AuthManager";

class OpportunitiesController {
  private authManager: AuthManager;

  constructor(AuthManager: AuthManager) {
    this.authManager = AuthManager;
  }

  public async getOpportunities(status: OpportunityDocumentStatus) {
    try {
      return this.authManager.request<Opportunity[]>(
        "POST",
        `/organizations/${this.authManager.organizationId}/opportunities/`,
        {
          status: status.toString(),
        },
      );
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public async getOpportunity(opportunityId: string) {
    try {
      return this.authManager.request<Opportunity>(
        "GET",
        `/organizations/${this.authManager.organizationId}/opportunities/${opportunityId}/`,
      );
    } catch (error) {
      console.error(error);
      return {} as Opportunity;
    }
  }

  public async getOpportunityPDF(opportunityId: string, language: string) {
    try {
      return this.authManager.request<string>(
        "POST",
        `/organizations/${this.authManager.organizationId}/opportunities/${opportunityId}/pdf/`,
        {
          language: language,
        },
      );
    } catch (error) {
      console.error(error);
      return "";
    }
  }

  public async updateStatus(
    opportunityId: string,
    status: OpportunityDocumentStatus,
  ) {
    try {
      return this.authManager.request<Opportunity>(
        "PUT",
        `/organizations/${this.authManager.organizationId}/opportunities/${opportunityId}/`,
        { status: status.toString() },
      );
    } catch (error) {
      console.error(error);
      return {} as Opportunity;
    }
  }

  public async shareOpportunity(
    opportunityId: string,
    emails: string[],
    note: string,
    title: string,
    client: string,
    region: string,
    url: string,
    subject: string,
    language: string,
    sendersEmail: string,
  ) {
    return this.authManager.request(
      "POST",
      `/organizations/${this.authManager.organizationId}/opportunities/${opportunityId}/share/`,
      {
        emails,
        note,
        title,
        client,
        region,
        url,
        subject,
        language,
        cc: [sendersEmail],
      },
    );
  }
}
export { OpportunitiesController };
