import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { SeevClient } from "@/lib/SeevClient";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { featureFlags } from "@/config/featureFlags";

const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const feedbackIntegration = Sentry.feedbackIntegration({
  id: "seev-sentry-feedback",
  colorScheme: "system",
  showBranding: false,
  triggerLabel: "Report an issue",
  formTitle: "Report an issue",
  formDescription: "Please describe the issue you are experiencing.",
  formSubmitLabel: "Submit",
  formCancelLabel: "Cancel",
  submitButtonLabel: "Submit Issue",
  triggerAriaLabel: "Report an issue",
  tags: {
    // Add custom tags when feedback is submitted
    page_name: document.title,
    user_id: SeevClient.authManager.getUser()?.id,
    org_id: SeevClient.authManager.getOrganizationId(),
    portfolio_id: SeevClient.authManager.getPortfolioId(),
  },
});

export function initSentry() {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
      ...(featureFlags().enableSentryFeedback ? [feedbackIntegration] : []),
    ],
    beforeSend(event) {
      if (event.user) {
        event.user.ip_address = undefined;
        event.user.email = undefined;
        event.user.id = undefined;
        event.user.username = undefined;
      }

      if (event.tags) {
        delete event.tags.user;
      }

      return event;
    },
    tracesSampleRate: 0.01,
    tracePropagationTargets: [
      "localhost",
      /^\//,
      /^https:\/\/app\.seev\.ai\/v1\/api/,
    ],
    profilesSampleRate: 0.01,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 0.1,
    environment: ENVIRONMENT,
    sendDefaultPii: false,
  });
}
