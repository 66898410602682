import PDFExplorer from "../../../../modules/FileRenderer/explorers/PDFExplorer/PDFExplorer";
import RequirementCard from "../../Cards/RequirementV2/Requirements";
import { useEffect, useState } from "react";
import { Project } from "@/types/entities/Project";
import {
  Requirements,
  RequiredDocument,
  RequiredCompanyExperience,
  RequiredExperience,
} from "@/types/entities/Requirements";
import CreateRequirementDialog from "./createNewReqv2";
import { useIntl } from "react-intl";
import { SeevClient } from "@/lib/SeevClient";

interface RequirementsProps {
  project: Project;
  file: string | null;
  onRequirementsUpdate: (requirements: Requirements) => void;
}

function RequirementsV2({
  project,
  file,
  onRequirementsUpdate,
}: RequirementsProps) {
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [currentFile, setCurrentFile] = useState<string | null>(file);
  const [currentFileId, setCurrentFileId] = useState<string | null>(
    project.documents ? project.documents[0] : null,
  );
  const intl = useIntl();

  const handleRequirementClick = async (
    requirement:
      | RequiredDocument
      | RequiredCompanyExperience
      | RequiredExperience,
  ) => {
    if (requirement.documentId && requirement.documentId !== currentFileId) {
      const file = await SeevClient.file.downloadFile(
        requirement.documentId,
        true,
      );
      if (typeof file !== "string") {
        const fileURL = URL.createObjectURL(file);
        setCurrentFile(fileURL);
      }
      setCurrentFileId(requirement.documentId);
    }
    setPageNumber(Number(requirement.pageNumber));
    setSearchText(requirement.exactCopy);
  };

  const [selectedText, setSelectedText] = useState("");
  const [selectedPageNumber, setSelectedPageNumber] = useState(0);

  const onSubmitSelectedText = (text: string, pageNumber: number) => {
    setSelectedText(text);
    setSelectedPageNumber(pageNumber);
  };

  // This is to handle the case that the user switches to requirements tab immediately
  // after opening the project page while the initial file (documents[0]) is still loading.
  useEffect(() => {
    if (file && currentFile === null) {
      setCurrentFile(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  if (!project.requirementsV2) {
    return <div>Loading requirements...</div>;
  }

  return (
    <div className="flex flex-wrap xl:flex-nowrap h-full gap-4">
      {selectedText && selectedPageNumber && currentFileId && (
        <CreateRequirementDialog
          documentId={currentFileId}
          selectedText={selectedText}
          pageNumber={selectedPageNumber}
          requirements={project.requirementsV2}
          onRequirementsUpdate={onRequirementsUpdate}
        />
      )}
      <div className="flex h-full w-full">
        <RequirementCard
          requirements={project.requirementsV2}
          onRequirementsUpdate={onRequirementsUpdate}
          onRequirementClick={handleRequirementClick}
        />
      </div>
      <div className="flex h-full">
        <PDFExplorer
          fileUrl={currentFile}
          pageNumber={pageNumber}
          searchText={searchText}
          onSubmitSelectedText={onSubmitSelectedText}
          selectedTextButtonText={intl.formatMessage({
            id: "global.requirement",
          })}
        />
      </div>
    </div>
  );
}

export default RequirementsV2;
