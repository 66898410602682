import "../lib/sentry.ts";
import "./globals.css";
import AppProviders from "./AppProviders";
import AppRoutes from "./routes";
import { BrowserRouter } from "react-router-dom";
import Layout from "../components/Layout";
import { initSentry } from "../lib/sentry";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "@/components/Toaster/Toaster";

initSentry();

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProviders>
        <BrowserRouter>
          <Layout>
            <AppRoutes />
          </Layout>
        </BrowserRouter>
        <Toaster />
      </AppProviders>
    </QueryClientProvider>
  );
}

export default App;
