import { FormattedMessage, useIntl } from "react-intl";
import AddFileDialog from "./AddfFileDialog";
import { Button } from "@/components/ui/button";
import React from "react";
import { SearchInput } from "@/components/SearchInput/SearchInput";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

interface ToolBarProps {
  semanticSearch: (value: string) => void;
  onCreateFile: () => void;
}

function ToolBar({ semanticSearch, onCreateFile }: ToolBarProps) {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const semanticSearchPlaceholder = intl.formatMessage({
    id: "fileManager.semanticSearchPlaceholder",
  });
  const [semanticSearchText, setSemanticSearchText] = React.useState("");
  const [querySearch, setQuerySearch] = React.useState("");

  const handleSemanticSearch = (search: string) => {
    setSemanticSearchText(search);
    semanticSearch(search);
    navigate("", { replace: true });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get("query");
    if (query) {
      setQuerySearch(query);
    } else {
      setQuerySearch("");
    }
  }, [location.search]);

  return (
    <div className="flex  items-center justify-between gap-2">
      <div className="flex items-center">
        <AddFileDialog onCreate={onCreateFile} />
      </div>
      <div className="flex w-full items-center gap-2 justify-end">
        <div className="flex w-full max-w-[300px]">
          <SearchInput
            defaultValue={querySearch}
            placeholder={semanticSearchPlaceholder}
            onChange={(value) => setSemanticSearchText(value)}
            onSubmit={(value) => handleSemanticSearch(value)}
          />
        </div>
        <Button
          onClick={() => handleSemanticSearch(semanticSearchText)}
          disabled={!semanticSearchText}
        >
          <FormattedMessage id="fileManager.semanticSearchButton" />
        </Button>
      </div>
    </div>
  );
}
export default ToolBar;
