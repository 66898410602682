import { useCallback, useEffect, useRef, useState } from "react";
import { Document } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { FormattedMessage } from "react-intl";

import { pdfjs } from "react-pdf";
import { Loading } from "@/components/Loading/Loading";
import PDFPage from "./PDFPage";
import PageSelector from "./PageSelector";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url,
).toString();

interface PDFExplorerProps {
  fileUrl?: string | null;
  searchText?: string;
  pageNumber?: number;
  updateCurrentPage: (page: number) => void;
  pagesToRender: number[];
}

function PDFRenderer({
  fileUrl,
  searchText,
  pageNumber,
  updateCurrentPage,
  pagesToRender,
}: PDFExplorerProps) {
  const [totalPages, setTotalPages] = useState<number>(0);
  const [goToPage, setGoToPage] = useState<number | null>(null);
  const renderedPages = useRef<Set<number>>(new Set());
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    if (
      totalPages > 0 &&
      pageNumber &&
      pageNumber >= 0 &&
      pageNumber <= totalPages
    ) {
      renderedPages.current.add(pageNumber);
      setGoToPage(pageNumber);
    }

    // We don't want to update the component when the totalPages changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const renderPages = useCallback(() => {
    const pages = [];
    const pageNumbers =
      pagesToRender.length > 0
        ? pagesToRender
        : Array.from({ length: totalPages }, (_, i) => i + 1);

    for (const i of pageNumbers) {
      if (i <= totalPages) {
        pages.push(
          <div
            className="border-t-[1px] border-b-[1px] border-gray-200"
            key={i}
          >
            <PDFPage
              pageNumber={i}
              activePage={goToPage || 0}
              searchText={searchText || ""}
              onScreen={(isOnScreen) => {
                if (isOnScreen) {
                  renderedPages.current.add(i);
                } else {
                  renderedPages.current.delete(i);
                }
                const minPage = Math.min(...Array.from(renderedPages.current));
                setCurrentPage(minPage);
                updateCurrentPage(minPage);
              }}
            />
          </div>,
        );
      }
    }
    return pages;
  }, [
    totalPages,
    goToPage,
    searchText,
    renderedPages,
    updateCurrentPage,
    pagesToRender,
  ]);

  if (!fileUrl) {
    return (
      <div>
        <FormattedMessage id="global.pdfEmpty"></FormattedMessage>
      </div>
    );
  }

  return (
    <div className="flex">
      <Document
        onLoadSuccess={(document) => {
          setTotalPages(document.numPages);
        }}
        className={"m-0 p-0 w-full"}
        file={fileUrl}
        loading={<Loading messageId="global.file.loading" />}
      >
        <div className="flex flex-col gap-[2px] bg-gray-100">
          {renderPages()}
        </div>
      </Document>
      <PageSelector
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page: number) => {
          setGoToPage(page);
          setCurrentPage(page);
          updateCurrentPage(page);
        }}
      />
    </div>
  );
}

export default PDFRenderer;
