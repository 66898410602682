import FileEntity, { FileType } from "@/types/entities/File";
import { SemanticSearchFileEntity } from "@/types/entities/File";
import { AuthManager } from "../AuthManager";
import FileCacheService from "../FileCache";

// TODO: Remove the try catch let the caller handle the error
class FileController {
  private authManager: AuthManager;

  constructor(authManager: AuthManager) {
    this.authManager = authManager;
  }

  public async getFiles(abortSignal?: AbortSignal): Promise<FileEntity[]> {
    try {
      const files = await this.authManager.request<FileEntity[]>(
        "GET",
        `/organizations/${this.authManager.organizationId}/documents/`,
        {},
        "json",
        abortSignal,
      );

      return files;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public async getFile(fileId: string): Promise<FileEntity> {
    const file = await this.authManager.request<FileEntity>(
      "GET",
      `/organizations/${this.authManager.organizationId}/documents/${fileId}/`,
    );

    return file;
  }

  public async downloadFile(file_id: string, convertToPdf: boolean = false) {
    if (!file_id) {
      throw new Error("File id is required");
    }
    try {
      const path = `/organizations/${this.authManager.organizationId}/documents/${file_id}/file/?convert_to_pdf=${convertToPdf}`;
      const cachedFile = FileCacheService.get(path);

      if (cachedFile) return cachedFile;

      const fileArrayBuffer = await this.authManager.request<ArrayBuffer>(
        "GET",
        path,
        undefined,
        "arraybuffer",
      );

      // TODO: make this dynamic based on the file type
      const fileBlob = new Blob([fileArrayBuffer], { type: "application/pdf" });

      FileCacheService.add(path, fileBlob);

      return fileBlob;
    } catch (error) {
      console.error(error);
      return "";
    }
  }

  public async deleteFile(file: FileEntity) {
    try {
      return this.authManager.request(
        "DELETE",
        `/organizations/${this.authManager.organizationId}/documents/${file.id}/`,
      );
    } catch (error) {
      console.error(error);
    }
  }

  public async uploadNewFile(
    fileData: File,
    parse: boolean,
    type: FileType,
    action:
      | "CREATE_PROJECT"
      | "UPDATE_PROJECT"
      | "UPDATE_OUTLINE_DOCUMENTS"
      | null = null,
    projectId: string | null = null,
    convertToPdf: boolean = false,
  ) {
    const formData = new FormData();
    formData.append("file", fileData);
    formData.append("convert_to_pdf", convertToPdf ? "true" : "false");
    formData.append("type", type);

    const document = await this.authManager.request<FileEntity>(
      "POST",
      `/organizations/${this.authManager.organizationId}/documents/`,
      formData,
    );

    if (parse) {
      this.authManager.request(
        "POST",
        `/v2/organizations/${this.authManager.organizationId}/documents/${document.id}/parse/`,
        { action, projectId },
      );
    }
    return document;
  }

  public async semanticSearchFiles(
    text: string,
    abortSignal?: AbortSignal,
    typeName?: FileType,
  ) {
    try {
      const files = await this.authManager.request<SemanticSearchFileEntity[]>(
        "POST",
        `/organizations/${this.authManager.organizationId}/relevant-documents/`,
        { query: text, typeName },
        "json",
        abortSignal,
      );

      return files;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
}
export { FileController };
